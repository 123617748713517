import React, {FC, useCallback, useState} from 'react'
import {Link, useHistory} from 'react-router-dom'
import {useTranslation} from 'react-i18next'
import {
  AccountBalanceWalletIcon,
  AutoAwesomeIcon,
  ExitToAppIcon,
  FavoriteBorderIcon,
  GlobeIcon,
  HelpOutlineIcon,
  MoreHorizIcon,
  NightsStayIcon,
  SaveAltIcon,
  SyncDisabledIcon,
  SyncIcon,
  WbSunnyIcon,
  WhatshotIcon,
} from '6-shared/ui/Icons'
import {
  Divider, ListItemButton,
  ListItemIcon,
  ListItemSecondaryAction,
  ListItemText,
  ListSubheader,
  MenuItem,
  MenuList,
  PopoverProps,
  Switch,
  Typography,
} from '@mui/material'
import {sendEvent} from '6-shared/helpers/tracking'
import {useSnackbar} from '6-shared/ui/SnackbarProvider'
import {AdaptivePopover} from '6-shared/ui/AdaptivePopover'
import {appVersion} from '6-shared/config'

import {useAppDispatch} from 'store'
import {resetData} from 'store/data'

import {userSettingsModel} from '5-entities/userSettings'
import {useRegularSync} from '3-widgets/RegularSyncHandler'
import {logOut} from '4-features/authorization'
import {exportCSV} from '4-features/export/exportCSV'
import {exportJSON} from '4-features/export/exportJSON'
import {clearLocalData} from '4-features/localData'
import {
  convertZmBudgetsToZerro
} from '4-features/budget/convertZmBudgetsToZerro'
import {registerPopover} from '6-shared/historyPopovers'
import {useConfirm} from '6-shared/ui/SmartConfirm'
import {useColorScheme} from '6-shared/ui/theme'
import {settingsItems} from "./index";

const settingsHooks = registerPopover<{}, PopoverProps>('settingsMenu', {})

export const useSettingsMenu = () => {
  const {open} = settingsHooks.useMethods()
  return useCallback(
    (e: React.MouseEvent) => {
      open({}, {anchorEl: e.currentTarget})
    },
    [open]
  )
}

type SettingsMenuProps = { showLinks?: boolean }

export const SettingsMenu: FC<SettingsMenuProps> = props => {
  const {showLinks} = props
  const {displayProps} = settingsHooks.useProps()
  return (
    <AdaptivePopover {...displayProps}>
      <MenuList>
        <Settings showLinks={showLinks} onClose={displayProps.onClose}/>
      </MenuList>
    </AdaptivePopover>
  )
}

export const Settings = (props: {
  onClose: () => void;
  showLinks?: boolean
  showTitle?: boolean
}) => {
  const {t} = useTranslation('settings')
  return (
    <>
      {props.showTitle && <ListSubheader disableSticky={true}>{t('settings')}</ListSubheader>}

      <ReloadDataItem onClose={props.onClose}/>
      <AutoSyncItem/>
      <BudgetSettingsItem/>
      <ThemeItem onClose={props.onClose}/>


      <Divider light/>
      <ListSubheader disableSticky={true}>{t('export')}</ListSubheader>
      <ExportCsvItem/>
      <ExportJsonItem/>
      <Divider light/>

      <SettingsHelpItems />

      <Divider light/>
      <LangItem onClose={props.onClose}/>
      <LogOutItem onClose={props.onClose}/>
    </>
  )
}

type ItemProps = { onClose: () => void }

function ExportCsvItem() {
  const {t} = useTranslation('settings')
  const dispatch = useAppDispatch()
  const handleExportCSV = () => {
    sendEvent('Settings: export csv')
    dispatch(exportCSV)
  }
  return (
    <MenuItem onClick={handleExportCSV}>
      <ListItemIcon>
        <SaveAltIcon/>
      </ListItemIcon>
      <ListItemText>{t('downloadCSV')}</ListItemText>
    </MenuItem>
  )
}

function ExportJsonItem() {
  const {t} = useTranslation('settings')
  const dispatch = useAppDispatch()
  const handleExportCSV = () => {
    sendEvent('Settings: export json')
    dispatch(exportJSON)
  }
  return (
    <MenuItem onClick={handleExportCSV}>
      <ListItemIcon>
        <SaveAltIcon/>
      </ListItemIcon>
      <ListItemText>{t('fullBackup')}</ListItemText>
    </MenuItem>
  )
}

function ThemeItem({onClose}: ItemProps) {
  const {t} = useTranslation('settings')
  const theme = useColorScheme()
  const handleThemeChange = () => {
    sendEvent('Settings: toggle theme')
    onClose()
    theme.toggle()
  }
  return (
    <MenuItem onClick={handleThemeChange}>
      <ListItemIcon>
        {theme.mode === 'dark' ? <WbSunnyIcon/> : <NightsStayIcon/>}
      </ListItemIcon>
      <ListItemText>
        {t(theme.mode === 'dark' ? 'lightMode' : 'darkMode')}
      </ListItemText>
    </MenuItem>
  )
}

function LangItem({onClose}: ItemProps) {
  const {t, i18n} = useTranslation('settings')
  const currentLang = i18n.resolvedLanguage || i18n.language

  const setNextLang = () => {
    const nextLang = currentLang === 'en' ? 'ru' : 'en'
    sendEvent(`Settings: change language to ${nextLang}`)
    i18n.changeLanguage(nextLang)
  }

  return (
    <MenuItem onClick={setNextLang}>
      <ListItemIcon>
        <GlobeIcon/>
      </ListItemIcon>
      <ListItemText>{t('language')}</ListItemText>
      <ListItemSecondaryAction>
        {currentLang.toUpperCase()}
      </ListItemSecondaryAction>
    </MenuItem>
  )
}

function NavItems({onClose}: ItemProps) {
  const {t} = useTranslation('navigation')
  const history = useHistory()
  const handleNav =
    (path: string): React.MouseEventHandler<HTMLAnchorElement> =>
      e => {
        e.preventDefault()
        onClose()
        setTimeout(() => history.push(path), 10)
      }
  return (
    <>
      <MenuItem onClick={handleNav('/accounts')} component={Link} to="/stats">
        <ListItemIcon>
          <AccountBalanceWalletIcon/>
        </ListItemIcon>
        <ListItemText>{t('accounts')}</ListItemText>
      </MenuItem>

      <MenuItem onClick={handleNav('/review')} component={Link} to="/review">
        <ListItemIcon>
          <WhatshotIcon/>
        </ListItemIcon>
        <ListItemText>{t('yearWrapped')}</ListItemText>
      </MenuItem>

      <MenuItem onClick={handleNav('/about')} component={Link} to="/about">
        <ListItemIcon>
          <HelpOutlineIcon/>
        </ListItemIcon>
        <ListItemText>{t('about')}</ListItemText>
      </MenuItem>

      <MenuItem
        onClick={handleNav('/donation')}
        component={Link}
        to="/donation"
      >
        <ListItemIcon>
          <FavoriteBorderIcon/>
        </ListItemIcon>
        <ListItemText>{t('donate')}</ListItemText>
      </MenuItem>

      <Divider light/>
    </>
  )
}

function ReloadDataItem({onClose}: ItemProps) {
  const {t} = useTranslation('settings')
  const dispatch = useAppDispatch()
  const reloadData = () => {
    sendEvent('Settings: reload data')
    dispatch(resetData())
    dispatch(clearLocalData())
    window.location.reload()
  }
  const reload = useConfirm({onOk: reloadData})
  return (
    <MenuItem onClick={reload}>
      <ListItemIcon>
        <SyncIcon/>
      </ListItemIcon>
      <ListItemText>{t('reloadData')}</ListItemText>
    </MenuItem>
  )
}

function AutoSyncItem() {
  const {t} = useTranslation('settings')
  const [regular, setRegular] = useRegularSync()
  const handleClick = () => {
    sendEvent(`Settings: turn sync ${regular ? 'off' : 'on'}`)
    setRegular(c => !c)
  }
  return (
    <MenuItem onClick={handleClick}>
      <ListItemIcon>
        {regular ? <SyncIcon/> : <SyncDisabledIcon/>}
      </ListItemIcon>
      <ListItemText>{t('regularSync')}</ListItemText>
      <Switch edge="end" checked={regular}/>
    </MenuItem>
  )
}

function BudgetSettingsItem() {
  const {t} = useTranslation('settings')
  const dispatch = useAppDispatch()
  const setSnackbar = useSnackbar()
  const {preferZmBudgets} = userSettingsModel.useUserSettings()
  const toggleSetting = () => {
    sendEvent(`Settings: preferZmBudgets ${preferZmBudgets ? 'off' : 'on'}`)
    dispatch(userSettingsModel.patch({preferZmBudgets: !preferZmBudgets}))
  }
  const convertBudgets = () => {
    sendEvent(`Settings: convert old budgets`)
    const updated = dispatch(convertZmBudgetsToZerro())
    setSnackbar({
      message: t('budgetsConverted', {
        budgets: updated.length,
      }),
    })
  }

  return (
    <>
      <MenuItem onClick={toggleSetting}>
        <ListItemIcon>
          <AutoAwesomeIcon/>
        </ListItemIcon>
        <ListItemText
          sx={{whiteSpace: 'normal'}}
          primary={t('useZmBudgets')}
          secondary={t('useZmBudgetsDescription')}
        />
        <Switch edge="end" checked={!!preferZmBudgets}/>
      </MenuItem>

      {!preferZmBudgets && (
        <MenuItem onClick={convertBudgets}>
          <ListItemIcon>
            <AutoAwesomeIcon/>
          </ListItemIcon>
          <ListItemText
            sx={{whiteSpace: 'normal'}}
            primary={t('convertBudgetsFromZm')}
          />
        </MenuItem>
      )}
    </>
  )
}

function LogOutItem({onClose}: ItemProps) {
  const {t} = useTranslation('settings')
  const dispatch = useAppDispatch()
  const handleClick = () => {
    onClose()
    sendEvent('Settings: log out')
    dispatch(logOut())
  }
  return (
    <MenuItem onClick={handleClick}>
      <ListItemIcon>
        <ExitToAppIcon/>
      </ListItemIcon>
      <ListItemText>{t('logOut')}</ListItemText>
    </MenuItem>
  )
}

function VersionItem({onClose}: ItemProps) {
  const {t} = useTranslation('settings')
  return (
    <MenuItem
      onClick={() => {
        onClose()
        window.location.reload()
      }}
    >
      <ListItemIcon/>
      <ListItemText>
        <Typography variant="overline" color="textSecondary">
          {t('version', {version: appVersion})}
        </Typography>
      </ListItemText>
    </MenuItem>
  )
}

function SettingsHelpItems() {
  const { t } = useTranslation('newNav');
 return  settingsItems.subItems.map(item =>
  (<MenuItem>
    <ListItemText>
      <Typography variant="overline" color="textSecondary">
        {/*// @ts-ignore*/}
        {t(item.text)}
      </Typography>
    </ListItemText>
  </MenuItem>)
  )
}
