import {useEffect} from 'react';
import {useAppDispatch, useAppSelector} from 'store';
import {getTransactionsExtraData} from "./rocketApiMethods";
import {setReady, upsertItems} from "store/rocketData";
import {getTransactions} from "../../../5-entities/transaction/model";


const useFetchRocketData = () => {

    const dispatch = useAppDispatch();
    const transactions = useAppSelector(getTransactions);
    // const transactions = useAppSelector(state => state.data.current.transaction);
    const fetchAndUpdate = async () => {
        if (!transactions) return;

        const transactionIds = Object.keys(transactions);
        try {
            dispatch(setReady(false))
            const extraData = await getTransactionsExtraData(transactionIds);
            // extraData.forEach(item => {
                dispatch(upsertItems(extraData))
            // });

        } catch (error) {
            console.error('Error fetching rocket data:', error);
        } finally {
            console.log('all rocket data loaded')
            dispatch(setReady(true))
        }
    };
    useEffect(() => {
        fetchAndUpdate();
    }, [transactions, dispatch, fetchAndUpdate]);
};
export default useFetchRocketData;
