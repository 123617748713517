import {useCallback, useEffect, useMemo, useState} from 'react';
import {TFxAmount, TISOMonth} from '6-shared/types';
import {useAppSelector} from "../../../store";
import {getMergedTransactionsMonths} from "../../../store/rocketData";
import {toISOMonth} from "../../../6-shared/helpers/date";

export const usePnLMonthsHandler = () => {
    const allMonths = useAppSelector(getMergedTransactionsMonths);

    const defaultMonthsArray = useMemo(() => {
        if (!allMonths || allMonths.length === 0) {
            const currentMonth = toISOMonth(new Date());
            return [currentMonth];
        }

        const currentMonth = toISOMonth(new Date());
        const index = allMonths.indexOf(currentMonth);

        if (allMonths.length < 2) return [currentMonth];
        if (index === -1) return [allMonths.at(-2), allMonths.at(-1)];
        if (index === 0) return [currentMonth, allMonths.at(1)];
        if (index >= 1) return [currentMonth, allMonths.at(index - 1)];

        return allMonths;
    }, [allMonths]);

    const [monthList, setMonthList] = useState(defaultMonthsArray);

    const onMonthsChange = useCallback((newMonthList: TISOMonth[]) => {
        setMonthList(newMonthList);
    }, []);

    const months = useMemo(() => {
        return monthList && monthList.length > 0
            ? [...monthList].sort()
            : [...defaultMonthsArray].sort();
    }, [monthList, defaultMonthsArray]);

    return { allMonths, monthList, months, onMonthsChange };
};




