import { useCallback, useEffect, useState, useMemo, useRef } from "react";
import useTableDefaultTemplate from "../table-template";

type GroupValue = {
    date: string;
    totalActivity: number;
};

type Group = {
    id: string;
    values: GroupValue[];
};

type TemplateGroup = {
    id: string;
    formula?: string;
    sub_group?: TemplateGroup[];
};

export const useFormulaGroup = (
    months: string[],
    getAllGroupValues: Group[],
    groupTotals: Group[]
) => {
    const { defaultPLTemplateStructure, findGroupById } = useTableDefaultTemplate();
    const [calculatedResults, setCalculatedResults] = useState<Group[]>([]);
    const prevValuesRef = useRef({ groupValues: null, totals: null });

    // Рекурсивная функция для сбора всех групп с формулами
    const collectFormulaGroups = useCallback((groups: TemplateGroup[]) => {
        const result: TemplateGroup[] = [];

        const processGroup = (group: TemplateGroup) => {
            if (group.formula) {
                result.push(group);
            }
            if (group.sub_group) {
                group.sub_group.forEach(processGroup);
            }
        };

        groups.forEach(processGroup);
        return result;
    }, []);

    // Мемоизируем структуру формул и строим граф зависимостей
    const { formulaGroups, dependencyGraph } = useMemo(() => {
        const groups = collectFormulaGroups(defaultPLTemplateStructure);
        const graph = new Map<string, string[]>();
        const processed = new Set<string>();

        const addDependencies = (group: TemplateGroup) => {
            if (!group.formula || processed.has(group.id)) return;

            processed.add(group.id);
            const [operand1Id, , operand2Id] = group.formula.split(' ');
            graph.set(group.id, [operand1Id, operand2Id]);

            // Добавляем зависимости от родительской группы для дочерних
            if (group.sub_group) {
                group.sub_group.forEach(subGroup => {
                    if (subGroup.formula) {
                        const existingDeps = graph.get(subGroup.id) || [];
                        graph.set(subGroup.id, [...existingDeps, group.id]);
                        addDependencies(subGroup);
                    }
                });
            }
        };

        defaultPLTemplateStructure.forEach(group => addDependencies(group));

        return { formulaGroups: groups, dependencyGraph: graph };
    }, [defaultPLTemplateStructure, collectFormulaGroups]);

    const calculateFormula = useCallback(
        (groupId: string, allGroups: Group[]) => {
            const formulaGroup = findGroupById(groupId, defaultPLTemplateStructure);
            if (!formulaGroup?.formula) return [];

            const [operand1Id, operator, operand2Id] = formulaGroup.formula.split(' ');
            const operand1Group = allGroups.find(g => g.id === operand1Id);
            const operand2Group = allGroups.find(g => g.id === operand2Id);

            if (!operand1Group?.values || !operand2Group?.values) return [];

            return months.map(date => {
                const operand1Value = operand1Group.values.find(v => v.date === date)?.totalActivity ?? 0;
                const operand2Value = operand2Group.values.find(v => v.date === date)?.totalActivity ?? 0;

                let calculationResult = 0;
                switch (operator) {
                    case '+':
                        calculationResult = operand1Value + operand2Value;
                        break;
                    case '-':
                        calculationResult = operand1Value - operand2Value;
                        break;
                    case '/':
                        calculationResult = operand2Value !== 0 ? operand1Value / operand2Value : 0;
                        break;
                    default:
                        return null;
                }
                return { date, totalActivity: calculationResult };
            }).filter(Boolean);
        },
        [findGroupById, defaultPLTemplateStructure, months]
    );

    // Функция для определения порядка вычисления формул с учетом вложенности
    const getCalculationOrder = useCallback(() => {
        const visited = new Set<string>();
        const order: string[] = [];
        const inProcess = new Set<string>(); // Для обнаружения циклических зависимостей

        function visit(groupId: string) {
            if (visited.has(groupId)) return;
            if (inProcess.has(groupId)) {
                console.warn(`Обнаружена циклическая зависимость в формулах для группы ${groupId}`);
                return;
            }

            inProcess.add(groupId);

            const dependencies = dependencyGraph.get(groupId) || [];
            dependencies.forEach(depId => {
                // Проверяем, является ли зависимость формулой
                if (dependencyGraph.has(depId)) {
                    visit(depId);
                }
            });

            inProcess.delete(groupId);
            visited.add(groupId);
            order.push(groupId);
        }

        formulaGroups.forEach(group => {
            if (!visited.has(group.id)) {
                visit(group.id);
            }
        });

        return order;
    }, [dependencyGraph, formulaGroups]);

    useEffect(() => {
        const currentGroupValues = getAllGroupValues;
        const currentTotals = groupTotals;

        const hasChanges = !areArraysEqual(currentGroupValues, prevValuesRef.current.groupValues) ||
            !areArraysEqual(currentTotals, prevValuesRef.current.totals);

        if (!hasChanges) {
            return;
        }

        prevValuesRef.current = {
            // @ts-ignore
            groupValues: currentGroupValues,
            // @ts-ignore
            totals: currentTotals
        };

        const calculationOrder = getCalculationOrder();
        let allGroups = [...currentGroupValues, ...currentTotals];

        // Вычисляем формулы в правильном порядке
        const newResults: Group[] = [];
        calculationOrder.forEach(groupId => {
            const calculatedValues = calculateFormula(groupId, allGroups);
            const result = { id: groupId, values: calculatedValues };

            // Обновляем существующий результат или добавляем новый
            const existingIndex = newResults.findIndex(r => r.id === groupId);
            if (existingIndex !== -1) {
                // @ts-ignore
                newResults[existingIndex] = result;
            } else {
                // @ts-ignore
                newResults.push(result);
            }

            // Обновляем allGroups с новым результатом
            const groupIndex = allGroups.findIndex(g => g.id === groupId);
            if (groupIndex !== -1) {
                // @ts-ignore
                allGroups[groupIndex] = result;
            } else {
                // @ts-ignore
                allGroups.push(result);
            }
        });

        const hasResultsChanged = !areArraysEqual(newResults, calculatedResults);
        if (hasResultsChanged) {
            setCalculatedResults(newResults);
        }
    }, [getAllGroupValues, groupTotals, calculateFormula, getCalculationOrder]);

    const getCalculatedResults = useCallback((id: string) => {
        return calculatedResults.find(e => e.id === id)?.values ?? [];
    }, [calculatedResults]);

    return { getCalculatedResults };
};

// Вспомогательная функция для глубокого сравнения массивов
function areArraysEqual(arr1: any, arr2: any): boolean {
    if (!arr1 || !arr2) return false;
    if (arr1 === arr2) return true;

    if (arr1.length !== arr2.length) return false;

    for (let i = 0; i < arr1.length; i++) {
        const item1 = arr1[i];
        const item2 = arr2[i];

        if (typeof item1 !== typeof item2) return false;

        if (typeof item1 === 'object') {
            if (!areArraysEqual(Object.values(item1), Object.values(item2))) {
                return false;
            }
        } else if (item1 !== item2) {
            return false;
        }
    }

    return true;
}