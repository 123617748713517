import React, {FC, useCallback, useState} from 'react'
import {TransactionList} from '3-widgets/transaction/TransactionList'
import {
  Box,
  Paper,
  Theme,
  useMediaQuery,
} from '@mui/material'

import {Helmet} from 'react-helmet'
import {useTranslation} from 'react-i18next'
import {
  useTransactionPreview
} from "../../3-widgets/global/TransactionPreviewDrawer";

const sideWidth = 360
const sideSx = {
  width: sideWidth,
  flexShrink: 0,
  overflow: 'auto',
  bgcolor: 'background.paper',
}

export default function TransactionsView() {
  const {t} = useTranslation('transactions')
  const isMobile = useMediaQuery<Theme>(theme => theme.breakpoints.down('md'))
  const [checkedDate, setCheckedDate] = useState<Date | null>(null)
  const trPreview = useTransactionPreview()

  const showTransaction = useCallback(
    (id: string) => {
      trPreview.open({
        id,
        onOpenOther: (id: string) => {
          // trPreview.close()
          showTransaction(id)
        },
        onSelectSimilar: () => {
          // TODO: implement
        },
      })
    },
    [trPreview]
  )

  return (
    <>
      <Helmet>
        <title>{t('pageTitle')}</title>
        <meta name="description" content={t('pageDescription')}/>
        <link rel="canonical" href="https://zerro.app/transactions"/>
      </Helmet>

      <Box display="flex" height="100dvh" pb={7}>
        <Box
          p={{xs: 0, md: 2}}
          flexGrow={1}
          minWidth={0}
          display="flex"
          justifyContent="center"
        >
          <Paper
            sx={{
              flex: '1 1 auto',
              display: 'flex',
              overflow: 'hidden',
              pb: {xs: 2, md: 4},

            }}
          >
            <TransactionList
              checkedDate={checkedDate}
              sx={{flex: '1 1 auto'}}
              onTrOpen={showTransaction}
            />
          </Paper>
        </Box>

      </Box>
    </>
  )
}
