import React, {useEffect, useState} from 'react'
import {
  Box,
  Collapse,
  Drawer,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemText
} from '@mui/material'
import MenuIcon from '@mui/icons-material/Menu'
import CloseIcon from '@mui/icons-material/Close'
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import {useTranslation} from 'react-i18next'
import {MenuRoute, navigationRoutes} from "./index";
import {Logo} from "../../6-shared/ui/Logo";
import {useAppTheme} from "../../6-shared/ui/theme";
import {Link} from "react-router-dom";
import {Settings} from "./SettingsMenu";
import AccountList from "../account/AccountList";
import {DebtorList} from "../DebtorList";
import RefreshButton from "../RefreshButton";

const navBarHeight = '48px'; // Высота Navbar

export const MobileNavNew = () => {
  const {t} = useTranslation('newNav')
  const theme = useAppTheme()
  const routes: MenuRoute[] = navigationRoutes

  const [openLeftDrawer, setOpenLeftDrawer] = useState(false)
  const [openRightDrawer, setOpenRightDrawer] = useState(false)
  const [openBalancesSubMenu, setOpenBalancesSubMenu] = useState(false)
  const [openSubMenuIndex, setOpenSubMenuIndex] = useState<number | null>(null)

  const toggleLeftDrawer = () => {
    setOpenLeftDrawer((prev) => {
      if (!prev) {
        setOpenRightDrawer(false)
      }
      return !prev
    })
  }

  const toggleRightDrawer = () => {
    setOpenRightDrawer((prev) => {
      if (!prev) {
        setOpenLeftDrawer(false)
      }
      return !prev
    })
  }

  const handleSubMenuToggle = (index: number) => {
    setOpenSubMenuIndex(openSubMenuIndex === index ? null : index)
  }

  // @ts-ignore
  return (
    <Box>
      {/* Верхняя панель */}
      <Box display="flex" alignItems="center" justifyContent="space-between"
           height={navBarHeight} bgcolor="background.paper" p={1} zIndex={1000}>
        <IconButton onClick={toggleLeftDrawer}>
          {openLeftDrawer ? <CloseIcon/> : <MenuIcon/>}
        </IconButton>
        <Box display="flex" alignItems="center" justifyContent="center">
          <Logo fill={theme.palette.primary.main} width="100"/>
        </Box>
        <Box>
          <RefreshButton size={16} />
          <IconButton onClick={toggleRightDrawer}>
            {openRightDrawer ? <CloseIcon/> : <ManageAccountsIcon/>}
          </IconButton>
        </Box>

      </Box>


      {/* Drawer left*/}
      <Drawer anchor="left" open={openLeftDrawer} onClose={toggleLeftDrawer}
              style={{top: navBarHeight}}
              BackdropProps={{
                style: {
                  top: navBarHeight,
                  zIndex: 998
                }
              }}
              PaperProps={{
                style: {
                  height: '100%',
                  position: 'relative', // Чтобы не перекрывать NavBar
                  zIndex: 999, // Ниже, чем NavBar
                  boxShadow: 'none', // Убираем тень
                }
              }}>
        <Box role="presentation">
          <List>
            <ListItem button
                      onClick={() => setOpenBalancesSubMenu(!openBalancesSubMenu)}>
              <ListItemText primary={"Балансы"}/>
            </ListItem>
            <Collapse in={openBalancesSubMenu} timeout="auto"
                      unmountOnExit>
              <AccountList/>
              <DebtorList/>
            </Collapse>
            {routes.map((route, index) => (
              <div key={index}>
                <ListItem button onClick={() => handleSubMenuToggle(index)}>
                  {/*//@ts-ignore*/}
                  <ListItemText primary={t(route.text)}/>
                </ListItem>
                <Collapse in={openSubMenuIndex === index} timeout="auto"
                          unmountOnExit>
                  <Box paddingLeft={'32px'}>
                    <List component="div" disablePadding>
                      {route.subItems.map((subItem, subIndex) => (
                        <ListItemButton
                          key={subIndex}
                          component={Link}
                          to={subItem.path}
                          disabled={!subItem.enable}
                          onClick={toggleLeftDrawer}
                        >
                          {/*//@ts-ignore*/}
                          <ListItemText primary={t(subItem.text)}/>
                        </ListItemButton>
                      ))}
                    </List>
                  </Box>
                </Collapse>
              </div>
            ))}
          </List>

          <List>

          </List>

        </Box>
      </Drawer>
      <Drawer anchor="right" open={openRightDrawer} onClose={() =>setOpenRightDrawer(false)}
              style={{top: navBarHeight}}
              BackdropProps={{
                style: {
                  top: navBarHeight,
                  zIndex: 998
                }
              }}
              PaperProps={{
                style: {
                  height: '100%',
                  position: 'relative', // Чтобы не перекрывать NavBar
                  zIndex: 999, // Ниже, чем NavBar
                  boxShadow: 'none', // Убираем тень
                }
              }}>
        <Box role="presentation">
          <List>
            <ListItem>
              <ListItemText primary={"Настройки"} />
            </ListItem>
            <Settings showLinks={false}
                      onClose={() =>setOpenRightDrawer(false)}/>

          </List>
        </Box>
      </Drawer>
    </Box>
)
}
