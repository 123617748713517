import React from 'react'
import { Drawer, Box } from '@mui/material'
import { registerPopover } from '6-shared/historyPopovers'
import { TTransactionId } from '6-shared/types'
import {
  TransactionPreview,
  TransactionPreviewProps,
} from '../transaction/TransactionPreview'
import {DrawerWrapper} from "../../6-shared/ui/DrawerWrapper";

export type TransactionPreviewDrawerProps = {
  id: TTransactionId
  onOpenOther?: TransactionPreviewProps['onOpenOther']
  onSelectSimilar?: TransactionPreviewProps['onSelectSimilar']
}

const trDrawerHooks = registerPopover('transaction-preview-drawer', {
  id: '',
} as TransactionPreviewDrawerProps)

export const useTransactionPreview = trDrawerHooks.useMethods
export const useTransactionPreviewProps = trDrawerHooks.useProps

export const SmartTransactionPreview = () => {
  const drawer = trDrawerHooks.useProps()
  const { id, onOpenOther = () => {}, onSelectSimilar } = drawer.extraProps
  const { onClose, open } = drawer.displayProps
  return (
    <DrawerWrapper
      onClose={onClose}
      isOpen={open}
      position='right'
     >
      <Box height="100vh" display="flex" flexDirection="column" minWidth={320}>
        <TransactionPreview
          id={id}
          onClose={onClose}
          onOpenOther={onOpenOther}
          onSelectSimilar={onSelectSimilar}
        />
      </Box>
    </DrawerWrapper>
  )
}
