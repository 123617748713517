import React, {FC, memo, ReactNode, useMemo} from 'react'
import {useDraggable} from '@dnd-kit/core'
import {Box, ButtonBase, Chip, IconButton, Typography} from '@mui/material'
import {useTranslation} from 'react-i18next'

import {EmojiIcon} from '6-shared/ui/EmojiIcon'
import {DragIndicatorIcon} from '6-shared/ui/Icons'
import MessageIcon from '@mui/icons-material/Message'
import {TFxCode} from '6-shared/types'
import {Tooltip} from '6-shared/ui/Tooltip'
import {getCurrencySymbol} from '6-shared/helpers/money'
import {TEnvelope, TEnvelopeId} from '5-entities/envelope'
import {displayCurrency} from '5-entities/currency/displayCurrency'
import {DragTypes} from '2-pages/Budgets/DnD'

export const NameCell: FC<{
    envelope: TEnvelope
    isChild?: boolean
    isParent?: boolean
    isSelf?: boolean
    isReordering: boolean
    isDefaultVisible: boolean
    onClick?: () => void
}> = memo(props => {
    const {id, symbol, color, name, currency, comment, originalName} =
        props.envelope
    const {isReordering, isDefaultVisible, isChild, isSelf, isParent, onClick} = props

    const [displCurrency] = displayCurrency.useDisplayCurrency()
    const {t} = useTranslation('budgets')


    const nameCellPadding = useMemo(() => {
        const child = isChild ? 2 : 0
        const par = isParent ? 5 : 0
        return child + par
    }, [isParent, isChild])

    return (
        <Box
            display="flex"
            alignItems="center"
            justifyContent="flex-start"
            borderRight='divider'
            gap={1}
            minWidth={0}

            pl={nameCellPadding}
            sx={{flexGrow: 1}}
        >

            {isReordering && (
                <EnvDraggable id={id}>
                    <IconButton size="small" sx={envDraggableSx}>
                        <DragIndicatorIcon/>
                    </IconButton>
                </EnvDraggable>
            )}
            <ButtonBase sx={{p: 1, ml: -1, minWidth: 0, flexShrink: 1, justifyContent: 'flex-start', width: '100%'}}
                        onClick={onClick}>
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'flex-start',
                        alignItems: 'center',
                        flexGrow: 1,
                        minWidth: 0,
                        gap: '4px',
                        opacity: isDefaultVisible ? 1 : 0.5,
                    }}
                >
                    <EmojiIcon
                        symbol={isSelf ? '–' : symbol}
                        color={isSelf ? null : color}
                        mr={1.5}
                    />

                    <Typography
                        component="span"
                        variant="body1"
                        title={name}
                        noWrap
                    >
                        {isSelf ? `${name} ${t('isSelf')}` : name}
                    </Typography>
                    {displCurrency !== currency && <CurrencyTag currency={currency}/>}

                    {!!comment && <CommentTag comment={comment}/>}

                </Box>
            </ButtonBase>


        </Box>
    )
})

const envDraggableSx = {
    my: -1,
    display: 'grid',
    placeItems: 'center',
}

const EnvDraggable: FC<{ id: TEnvelopeId; children: ReactNode }> = props => {
    const {id, children} = props
    const {setNodeRef, attributes, listeners} = useDraggable({
        id: 'envelope' + id,
        data: {type: DragTypes.envelope, id: id},
    })
    return (
        <span
            style={{
                userSelect: 'none',
                cursor: 'grab',
                touchAction: 'manipulation',
            }}
            ref={setNodeRef}
            {...attributes}
            {...listeners}
        >
      {children}
    </span>
    )
}

const CurrencyTag: FC<{ currency?: TFxCode }> = ({currency}) => {
    const {t} = useTranslation('budgets')
    if (!currency) return null
    return (
        <Tooltip title={t('envelopeCurrencyTooltip', {currency})}>
            <Chip label={getCurrencySymbol(currency)} size="small"/>
        </Tooltip>
    )
}

const CommentTag: FC<{ comment?: string }> = ({comment}) => {
    if (!comment) return null
    return (
        <Tooltip title={<Typography
            sx={{flexShrink: 1000000, fontStyle: 'italic'}}
            color="text.hint"
            component="span"
            variant="body1"
            width={'100%'}
            title={comment}
        >
            {comment}
        </Typography>}>
            <MessageIcon htmlColor="gray" fontSize="small"/>
        </Tooltip>

    )
}
