import {createSelector} from '@reduxjs/toolkit'
import {compareTrDates, compareTrDatesWithRocket, isDeleted} from './helpers'
import {RootState, TSelector} from 'store'
import {withPerf} from '6-shared/helpers/performance'
import {TISODate} from '6-shared/types'
import {toISODate} from '6-shared/helpers/date'

export const getTransactions = (state: RootState) =>
    state.data.current.transaction

export const getRocketTransactions = (state: RootState) =>
    state.rocketData.items ?? []


const mergedTr = createSelector(
    [getRocketTransactions, getTransactions],
    (rocketTr, zenTr) => {
        return Object.values(zenTr)
            .map(transaction => {
            const additionalInfo = rocketTr.find(extra => extra.transaction_id === transaction.id)
            return additionalInfo ? {...transaction, ...additionalInfo} : transaction
        })
            .reduce((acc, transaction) => {
                // @ts-ignore
                acc[transaction.id] = transaction;
                return acc;
            }, {});
    }
)
/**
 * Transactions sorted from newest to oldest
 */
export const getSortedTransactions = createSelector(
    [getTransactions],

    withPerf('getSortedTransactions', transactions => {
            return Object.values(transactions).sort(compareTrDates)
        }
    )
)

export const getSortedTransactionsRocket = createSelector(
    [mergedTr],
    transactions => {
// @ts-ignore
       return  Object.values(transactions).sort(compareTrDatesWithRocket)
    }

)

/**
 * Transactions sorted from oldest to newest without deleted
 */
export const getTransactionsHistory = createSelector(
    [getSortedTransactions],
    withPerf('getTransactionsHistory', transactions =>
        transactions.filter(tr => !isDeleted(tr)).reverse()
    )
)

export const getTransactionsHistoryRocket = createSelector(
    [getSortedTransactionsRocket],
    withPerf('getTransactionsHistoryRocket', transactions =>
        // @ts-ignore
        transactions.filter(tr => !isDeleted(tr)).reverse()
    )
)

export const getHistoryStart: TSelector<TISODate> = createSelector(
    [getTransactionsHistory],
    history => {
        const firstReasonableDate = '2000-01-01' as TISODate
        const currentDate = toISODate(new Date())
        const firstTr = history.find(
            tr => tr.date >= firstReasonableDate && tr.date <= currentDate
        )
        if (!firstTr) return currentDate
        return firstTr.date
    }
)
