import React, {FC, ReactNode, useCallback, useMemo, useState} from 'react'
import {useDroppable} from '@dnd-kit/core'
import {envelopeModel, TEnvelopeId} from '5-entities/envelope'

import {TableRow} from '../shared/shared'
import {NameCell} from './NameCell'
import {ActivityCell} from './ActivityCell'
import {DragTypes} from "../../../Budgets/DnD";
import {ExpandActionButton} from "../../../FlowOfFunds/EnvelopeTable/ExpandActionButton";
import {Box, FormControl, MenuItem, Select} from "@mui/material";
import {displayCurrency} from "../../../../5-entities/currency/displayCurrency";
import {TFxAmount, TISOMonth} from "../../../../6-shared/types";
import {useTemplateMonths} from "../envelopesPnlContext";

type EnvelopeRowProps = {
    id: TEnvelopeId
    isSelf?: boolean
    isLastVisibleChild?: boolean
    isExpanded?: boolean
    onExpandToggle?: (id: TEnvelopeId) => void
    isEditMode: boolean
    openDetails: (id: TEnvelopeId) => void
    openTransactionsPopover: (ids: TEnvelopeId[]) => void
    isDefaultVisible: boolean
    templateGroupId: string
    moveEnvelope: (envelopeId: TEnvelopeId, fromGroupId: string, toGroupId: string) => void
    getTransactionsByEnvID: (id: TEnvelopeId) => any
    getEnvelopeById: (id:string) => any
    currConvert: (value: any, month: TISOMonth) => any
}

export const Row: FC<EnvelopeRowProps> = props => {
    const {
        id,
        isSelf,
        isEditMode,
        openTransactionsPopover,
        openDetails,
        onExpandToggle,
        isLastVisibleChild,
        isExpanded,
        isDefaultVisible,
        templateGroupId,
        moveEnvelope,
        getTransactionsByEnvID,
        getEnvelopeById,
        currConvert
    } = props

    const envelope = getEnvelopeById(id)
    const transactions = getTransactionsByEnvID(id)

    const values = useMemo(() => {
        return Object.entries(transactions).map(([month, transaction]) => {
            // @ts-ignore
            const toValue = (a: TFxAmount) => currConvert(a, month)

            // @ts-ignore
            const activity = isSelf ? toValue(transaction.selfActivity) : toValue(transaction.totalActivity)

            return [
                month,
                activity
            ];
        });
    }, [currConvert, isSelf, transactions]);


    const isChild = !!envelope.parent || !!isSelf
    const isParent = !!envelope.children

    const isExpandable = isExpanded !== undefined && onExpandToggle && !!envelope.children.length

    const handleNameClick = useCallback(() => {
        openDetails(id)
    }, [id, openDetails])

    const handleTrClick = useCallback((month: TISOMonth, isExact:boolean = false) => {
        const list = transactions[month][isExact ? 'selfTransactions' : 'totalTransactions']
        openTransactionsPopover(list)
    },[openTransactionsPopover, transactions])

    const TargetGroupForOperationsSelector: FC<{
        currentGroupId: string;
        onTargetGroupIdSelect: (groupId: string) => void;
    }> = ({currentGroupId, onTargetGroupIdSelect}) => {
        const options = [
            {id: 'hidden', name: "Доп. метрики(скрытое)"},
            {id: 'expenses', name: "Расходы(общая)"},
            {id: 'variable_expenses', name: "Переменные расходы"},
            {id: "fixed_expenses", name: "Постоянные расходы"},
            {id: 'other_expenses', name: "Прочие расходы"},
            {id: 'outside_operating_expenses', name: "Вне операционные расходы"}
        ];

        const [selectedOption, setSelectedOption] = useState(options.find(e => e.id === currentGroupId));
//@ts-ignore
        const handleChange = (event) => {
            const newOption = event.target.value;
            setSelectedOption(newOption);
            onTargetGroupIdSelect(newOption.id);
        };

        return (
            <FormControl sx={{m: 0, width: 200}}>
                <Select
                    value={selectedOption}
                    renderValue={() => selectedOption?.name}
                    onChange={handleChange}
                    size="small"
                    variant="outlined"
                >
                    {options.map((option) => (
                        //@ts-ignore
                        <MenuItem key={option.id} value={option}>{option.name}</MenuItem>
                    ))}
                </Select>
            </FormControl>
        );
    };

    const onSetTargetGroup = (targetId: string) => {

        moveEnvelope(id, templateGroupId, targetId);
    };

    const handleExpand = () => {
        if (isExpandable) onExpandToggle(id)
    }
    return (
        <Droppable
            id={id}
            isChild={isChild}
            isLastVisibleChild={!!isLastVisibleChild}
            isExpanded={!!isExpanded}
        >
            <TableRow
                className={'tableRow'}
                sx={{
                    cursor: 'pointer',
                    borderColor: 'divider',
                    borderBottom: 1,
                    '&:hover::before': {
                        content: '""',
                        position: 'absolute',
                        top: 0,
                        left: 0,
                        right: 0,
                        bottom: 0,
                        backgroundColor: 'var(--mui-palette-action-hover)',
                        zIndex: 3,
                        pointerEvents: 'none',
                        transition: 'background-color 0.1s ease-in-out',
                    },
                }}
                name={
                    <>
                        {isExpandable && <div style={{position: 'absolute', left: '16px'}}>
                            <ExpandActionButton isExpanded={isExpanded}
                                                toggleExpanded={e => handleExpand()}/>
                        </div>}
                        <NameCell
                            onClick={handleNameClick}
                            envelope={envelope}
                            isChild={isChild}
                            isParent={isParent}
                            isSelf={isSelf}
                            isDefaultVisible={isDefaultVisible}
                            isReordering={isEditMode}
                        />
                    </>
                }
                editor={isEditMode && (!isChild ?
                    <TargetGroupForOperationsSelector
                        currentGroupId={templateGroupId}
                        onTargetGroupIdSelect={onSetTargetGroup}
                    />: <Box sx={{m: 0, width: 200}}/>)
                }
                columns={
                    values.map(([month, activity]) =>
                         <ActivityCell
                            key={`${id} - ${month}`}
                            value={activity}
                            onClick={() => handleTrClick(month)}
                        />
                    )}
            />
        </Droppable>
    )
}

const Droppable: FC<{
    id: TEnvelopeId
    isChild: boolean
    isLastVisibleChild: boolean
    isExpanded: boolean
    children: ReactNode
}> = props => {
    const {id, isChild, isLastVisibleChild, isExpanded, children} = props
    const {setNodeRef} = useDroppable({
        id: 'envelope-drop' + id + isChild,
        data: {type: DragTypes.envelope, id, isLastVisibleChild, isExpanded},
    })

    return <div ref={setNodeRef}>{children}</div>
}
