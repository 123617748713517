import React, {FC, useMemo} from 'react'
import {Box, ButtonBase, Collapse, IconButton, Typography} from '@mui/material'
import {AddIcon} from '6-shared/ui/Icons'

import {useAppDispatch} from 'store/index'
import {TableRow} from './shared/shared'
import {Amount} from '6-shared/ui/Amount'
import {useTranslation} from 'react-i18next'
import {Tooltip} from "../../../6-shared/ui/Tooltip";
import {createEnvelope} from "../../../4-features/envelope/createEnvelope";
import {ExpandActionButton} from "../../FlowOfFunds/EnvelopeTable/ExpandActionButton";
import {TEnvelopeId} from "../../../5-entities/envelope";
import {TISOMonth} from "../../../6-shared/types";

type TGroupProps = {
    id: string
    groupName: string
    children?: React.ReactNode[]
    isSubgroup?: boolean
    isEditMode?: boolean
    onGroupNameClick: () => void
    getGroupTotals: (id: string) => Array<{ date: TISOMonth, totalActivity: number }>
    toggleExpand?: (id: string, isExpanded: boolean) => void
    isExpanded?: boolean
}
export const Group: FC<TGroupProps> = ({
                                           id,
                                           groupName,
                                           isEditMode,
                                           isSubgroup,
                                           children,
                                           getGroupTotals,
                                           onGroupNameClick,
                                           isExpanded,
                                           toggleExpand
                                       }) => {
    const {t} = useTranslation('budgets')
    const dispatch = useAppDispatch()

    const values = getGroupTotals(id)
    const Actions = (

        <Tooltip title={t('createEnvelope')}>
            <IconButton
                onClick={() => {
                    dispatch(createEnvelope({group: groupName, indexRaw: 0}))
                }
                }
            >
                <AddIcon/>
            </IconButton>
        </Tooltip>
    )

    // const [isExpanded, setIsExpanded] = useState(true)


    const GroupNameCell = (
        <Box
            sx={{
                pl: isSubgroup ? 2 : 0,
                pb: 0,
                minWidth: 0,
                display: 'flex',
                flexDirection: 'row',
                alignContent: 'flex-end',
                justifyContent: 'flex-start',
                alignItems: 'center',
                flexGrow: 1
            }}
        >
            {!!children
                ? <ExpandActionButton
                    isExpanded={isExpanded}
                    toggleExpanded={e => toggleExpand && toggleExpand(id, e)}/>
                : <Box sx={{width: '28px'}}/>}
            <ButtonBase
                sx={{p: 1, ml: -1, minWidth: 0, flexShrink: 1, justifyContent: 'flex-start', width: '100%'}}
                onClick={onGroupNameClick}
            >
                <Typography variant="h6" sx={{fontWeight: 600, fontSize: '1rem'}} noWrap>
                    {groupName}
                </Typography>
            </ButtonBase>

            {isEditMode && Actions}
        </Box>
    )

    const memoChildren = useMemo(() => {
        return children
    }, [children])


    const Sum: FC<{ value: number, onClick?: (ids: TEnvelopeId[]) => void }> = ({
                                                                                    value,
                                                                                    onClick
                                                                                }) => {
        return (
            <ButtonBase onClick={() => onClick}>
                <Typography alignSelf={'center'} color="text.primary" component="span"
                            variant="body1" align="center" noWrap
                            style={{
                                fontWeight: '500',
                                fontSize: '1rem'
                            }}>
                    <Amount value={value} decMode="ifAny"/>
                </Typography>
            </ButtonBase>

        )
    }
    // return useMemo(() => (
    return (<>
        <TableRow
            sx={{
                alignItems: 'center',
                borderBottom: 1,
                borderColor: 'divider',
            }}
            name={GroupNameCell}
            editor={isEditMode && <Box sx={{m: 0, width: 200}}/>}

            columns={
                values.map(({date, totalActivity}) => {
                        return <Sum
                            key={`${date}-${id}-totals`}
                            value={totalActivity}
                            // onClick={e => openTransactionsPopover(activity.ids)}
                        />
                    }
                )
            }
            // columns={[]}
        />
        <Box
            borderBottom={!isExpanded ? 1 : 0}
            borderTop={isExpanded ? 1 : 0}
            borderColor={'divider'}>
            <Collapse in={isExpanded}>
                {memoChildren}
            </Collapse>
        </Box>
    </>)

    // ), [ id, isEditMode, memoChildren, isExpanded, values, getGroupTotals])
}

