import React, { FC } from 'react'
import { Typography, Box } from '@mui/material'
import { Amount } from '6-shared/ui/Amount'
import { Btn } from './Btn'
import {TISOMonth} from "../../../../6-shared/types";
import {displayCurrency} from "../../../../5-entities/currency/displayCurrency";

type ActivityCellProps = {
  value: number
  onClick: React.MouseEventHandler<HTMLButtonElement>
  // month: TISOMonth
}

export const ActivityCell: FC<ActivityCellProps> = props => {
  const { value: displayActivity, onClick,  } = props
  // const { value: displayActivity, onClick, month } = props
  // const toDisplay = displayCurrency.useToDisplay(month)
  //@ts-ignore
  // const formattedVal = toDisplay(displayActivity)
  return (
    <Box
      display="flex"
      justifyContent="center"
      color={displayActivity ? 'text.primary' : 'text.hint'}
    >
      <Btn onClick={onClick} disabled={!displayActivity}>
      {/*<Btn onClick={onClick}>*/}
        <Typography variant="body1" align="right">
          <Amount value={displayActivity} decMode="ifOnly" />
        </Typography>
      </Btn>
    </Box>
  )
}
