import {useTranslation} from "react-i18next";
import {
  useTransactionPreview
} from "../../../../3-widgets/global/TransactionPreviewDrawer";
import React, {useCallback, useState} from "react";
import {Box, Drawer, IconButton, Typography} from "@mui/material";
import {Tooltip} from "../../../../6-shared/ui/Tooltip";
import {CloseIcon} from "../../../../6-shared/ui/Icons";
import {
  TransactionList
} from "3-widgets/transaction/TransactionList";
import {DrawerWrapper} from "6-shared/ui/DrawerWrapper";
import {TransactionPreview} from "../../../../3-widgets/transaction/TransactionPreview";


export const PNLTransactionListDrawer = (props: { transactions: any; title?: any; onClose: any; open: any; }) => {
  const {
    transactions,
    title,
    onClose,
    open,
  } = props
  const { t } = useTranslation('common')
  const trPreview = useTransactionPreview()
  const [openPreview, setOpenPreview] = useState(false)
 const [previewID, setPreviewID] = useState('')


  const showTransaction = useCallback(
    (id: string) => {
      setPreviewID(id)
      setOpenPreview(true)
      // trPreview.open({
      //   id,
      //   onOpenOther: (id: string) => {
      //     // trPreview.close()
      //     showTransaction(id)
      //   },
      //   onSelectSimilar: () => {
      //     // TODO: implement
      //   },
      // })

    },
    [trPreview]
  )

  const drawerWidth = { xs: '100vw', sm: '60vw' }
  const contentSx = {
    width: drawerWidth,
    transition: 'width 0.3s ease-in-out',
    [`& .MuiDrawer-paper`]: {
      width: drawerWidth,
      transition: 'width 0.3s ease-in-out',
    },
  };

  return (
      <DrawerWrapper position="right" isOpen={open} width={50}
                     onClose={onClose}>
      <Box height="100dvh" display="flex" flexDirection="column" minWidth={320}>
        <Box py={1} px={3} display="flex" alignItems="center">
          <Box flexGrow={1}>
            <Typography variant="h6" noWrap>
              {title || t('transactions')}
            </Typography>
          </Box>

          <Tooltip title={t('close')}>
            <IconButton edge="end" onClick={onClose} children={<CloseIcon />} />
          </Tooltip>
        </Box>

        <TransactionList
          transactions={transactions}
          onTrOpen={showTransaction}
          hideFilter
          sx={{ flex: '1 1 auto' }}
        />
      </Box>
        <DrawerWrapper
            onClose={() =>setOpenPreview(false)}
            isOpen={openPreview}
            position='right'
        >
          <Box height="100vh" display="flex" flexDirection="column" minWidth={320}>
            <TransactionPreview
                id={previewID}
                onClose={() => setOpenPreview(false)}
                onOpenOther={() =>{}}
                onSelectSimilar={() =>{}}
            />
          </Box>
        </DrawerWrapper>
    </DrawerWrapper>
  )
}
