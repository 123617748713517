import {IconButton} from "@mui/material";
import React, {FC} from 'react'
import {ChevronRightIcon} from '6-shared/ui/Icons'

type TExpandBtnProps = {
  toggleExpanded: (isExpanded:boolean) => void
  isExpanded?: boolean
}

export const ExpandActionButton: FC<TExpandBtnProps> = (
  {
    toggleExpanded,
    isExpanded,
  }
) => {
  return (
    <IconButton
      size="small"
      sx={{
        transform: isExpanded ? 'rotate(90deg)' : 'rotate(0deg)',
        transition: '.3s',
        zIndex: 44,
      }}
      onClick={e => toggleExpanded(!isExpanded)}
    >
      <ChevronRightIcon fontSize="inherit"/>
    </IconButton>
  )
}
