import React, {FC, useState} from 'react';
import {
  Box,
  Button,
  Drawer,
  IconButton,
  Paper,
  Typography,
  useTheme
} from '@mui/material';
import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet';
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import ChecklistIcon from '@mui/icons-material/Checklist';
import SmartToyIcon from '@mui/icons-material/SmartToy';
import ConstructionOutlinedIcon from '@mui/icons-material/ConstructionOutlined';
import {Logo} from "../../6-shared/ui/Logo";
import AccountList from "../account/AccountList";
import {DebtorList} from "../DebtorList";
import {useTranslation} from "react-i18next";
import {Link} from 'react-router-dom';
import {MenuRoute, navigationRoutes} from "./index";
import {Settings} from "./SettingsMenu";
import RefreshButton from "../RefreshButton";
import {Tooltip} from '6-shared/ui/Tooltip'
import {DrawerWrapper} from "6-shared/ui/DrawerWrapper";

const navBarHeight = '64px'; // Высота Navbar

export const NavBarDesktopNew = () => {
  const routes: MenuRoute[] = navigationRoutes;
  const theme = useTheme();
  const {t} = useTranslation('newNav');

  const [openTopDrawer, setOpenTopDrawer] = useState(false);
  const [isOpenLeftDrawer, setOpenLeftDrawer] = useState(false);
  const [isOpenRightDrawer, setOpenRightDrawer] = useState(false);
  const [activeSection, setActiveSection] = useState<number | null>(null);

  const onMenuClick = (index: number) => {
    setActiveSection(index);
    setOpenTopDrawer(!openTopDrawer);
    setOpenLeftDrawer(false);
    setOpenRightDrawer(false);
  };

  const toggleLeftDrawer = (state: boolean) => {
    setOpenLeftDrawer(state);
    state && setOpenTopDrawer(false);
  };

  const toggleRightDrawer = (state: boolean) => {
    setOpenRightDrawer(state);
    state && setOpenTopDrawer(false);
  };

  const LeftMenuWidget = () => {
    return (
      // <Box display="flex" alignItems="center" justifyContent="flex-start" sx={{ width: '15%', gap: 2 }}>
        <Box display="flex" alignItems="center" justifyContent="flex-start" sx={{ width: {lg: '10%', xl: '15%'}, gap: 2 }}>
        <Tooltip title={'Балансы'}>
          <IconButton onClick={() => toggleLeftDrawer(!isOpenLeftDrawer)}>
            <AccountBalanceWalletIcon sx={{ fontSize: { xs: 28, md: 32 } }} />
          </IconButton>
        </Tooltip>
        <Logo fill={theme.palette.primary.main} width="100" />
      </Box>
    );
  };

  const RightMenuWidget = () => {
    return (
      <Box display="flex" alignItems="center" justifyContent="flex-end" sx={{ width: {lg: '10%', xl: '15%'}, gap: 2 }}>
        <Paper
          elevation={1}
          sx={{ display: 'flex', alignItems: 'center', p: '0px 8px', borderRadius: '4px'}}
        >
        <Tooltip title="Чек-лист (в разработке)">
          <IconButton>
            <ChecklistIcon sx={{ fontSize: 18 }} />
          </IconButton>
        </Tooltip>
        <Tooltip title="ИИ помощник (в разработке)">
          <IconButton>
            <SmartToyIcon sx={{ fontSize: 18 }} />
          </IconButton>
        </Tooltip>
        <RefreshButton size={18} />
      </Paper>
        <Tooltip title="Личные настройки">
          <IconButton onClick={() => toggleRightDrawer(!isOpenRightDrawer)}>
            <ManageAccountsIcon sx={{ fontSize: { xs: 28, md: 32 } }} />
          </IconButton>
        </Tooltip>
      </Box>
    );
  };

  const CenterNavWidget = () => {
    return (
      <Box display="flex" justifyContent="flex-start" flexGrow={1} gap={{lg: 1, xl: 3}} ml={2}>
        {routes.map((section, index) => (
          // <Box display="flex" justifyContent="flex-start" key={section.text} width={`calc(70% / 4)`}>
          <Box display="flex" justifyContent="flex-start" key={section.text} sx={{
            width: {
              lg: `calc(80% / 4)`,
              xl: `calc(70% / 4)`
            }
          }}>
            <Button
              onClick={() => onMenuClick(index)}
              onMouseEnter={() => setActiveSection(index)}
              disabled={!section.enable}
              color="inherit"
              style={{
                width:  'max-content' , // Установка ширины кнопок через брейкпоинты
                display: 'flex',
                alignItems: 'flex-start',
                padding: '2px 8px'
              }}
            >
              <Box display='flex' alignItems='flex-start' width={'100%'}>
              <Typography
                variant="body1"
                sx={{
                  textAlign: 'start',
                  fontWeight: 'bold',
                  fontSize: { md: '0.8rem', lg: '1rem', xl: '1.2rem' } // Шрифт масштабируется
                }}
              >
                {/*//@ts-ignore*/}
                {t(section.text)}
              </Typography>
              </Box>
            </Button>
          </Box>
        ))}
      </Box>
    );
  };

  return (
    <>
      <Box display="flex" justifyContent="space-between" alignItems="center"
           p={1} bgcolor="background.paper" height={navBarHeight}
           position="relative" zIndex={1488}>
        <LeftMenuWidget />
        <CenterNavWidget />
        <RightMenuWidget />
      </Box>

      {/* Drawer, который открывается сверху под меню */}
      <Drawer
        anchor="top"
        open={openTopDrawer}
        sx={{ top: navBarHeight, zIndex: 998 }}
        onClose={() => setOpenTopDrawer(false)}
        PaperProps={{
          style: {
            height: 'max-content',
            position: 'relative',
            zIndex: 998,
            boxShadow: 'none',
          }
        }}
        BackdropProps={{
          style: {
            top: navBarHeight,
            zIndex: 998,
            pointerEvents: 'all'
          }
        }}
        SlideProps={{
          enter: false,
          style: {
            zIndex: 898,
            overflow: "hidden",
          }
        }}
      >
        {/*<Box bgcolor="background.paper" height="100%" px={'calc(15% + 8px)'}*/}
        <Box bgcolor="background.paper" height="100%"
             ml={2}
             sx={{
          // width: 'calc(70% / 4)',
          px: {
            lg: `calc(10% + 8px)`,
            xl: `calc(15% + 8px)`
          },
        }}
             display='flex'  gap={{lg: 1, xl: 3}} justifyContent="flex-start" pb={1}>
          {routes.map((section, index) => (
            <Box
              key={section.text}
              onMouseEnter={() => setActiveSection(index)}
              sx={{
                // width: 'calc(70% / 4)',
                width: {
                  lg: `calc(80% / 4)`,
                  xl: `calc(70% / 4)`
                },
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'flex-start',
                opacity: activeSection === index ? 1 : 0.3,
                // overflow: activeSection === index ? 'visible' : 'hidden',
                transition: 'all 0.3s ease',
                // "&:last-child": {
                //   width: 'calc((70% / 4) + 64px)',
                // }
              }}
            >
              {section.subItems.map((subItem) => (
                <MenuLink
                  key={subItem.text}
                  text={subItem.text}
                  path={subItem.path}
                  enabled={subItem.enable}
                  onClick={() => setOpenTopDrawer(false)} />
              ))}
            </Box>
          ))}
        </Box>
      </Drawer>

      {/* Left and Right Drawers */}
      <DrawerWrapper position={"left"} isOpen={isOpenLeftDrawer} onClose={() => setOpenLeftDrawer(false)}>
        <Box width="100%" px={1}>
          <AccountList />
        </Box>
        <Box width="100%" px={1}>
          <DebtorList />
        </Box>
      </DrawerWrapper>

      <DrawerWrapper position="right" isOpen={isOpenRightDrawer} onClose={() => setOpenRightDrawer(false)}>
        <Settings showLinks={false} showTitle={true} onClose={() => toggleRightDrawer(false)} />
      </DrawerWrapper>
    </>
  );
};

interface MenuLinkProps {
  text: string;
  enabled: boolean;
  path?: string;
  fontSize?: string;
  onClick?: () => void;
}

const MenuLink: FC<MenuLinkProps> = ({ text, enabled, path, onClick }) => {
  const { t } = useTranslation('newNav');
  return (
    <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-start'}}>
      {!enabled &&
        <Tooltip title="В разработке" placement="top">
          <IconButton sx={{ cursor: 'pointer' }}>
            <ConstructionOutlinedIcon sx={{ fontSize: 14, color: 'gray' }} />
          </IconButton>
        </Tooltip>
      }
      <Button
        component={Link}
        to={path || '*'}
        disabled={!enabled}
        onClick={onClick}
        sx={{
          minWidth: 0,
          width: 'max-content',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'flex-start',
          textTransform: 'none',
          fontWeight: enabled ? 'bold' : 'normal',
          padding: enabled ? '2px 8px' : 0,
          fontSize: { md: '0.4rem', lg: '0.8rem', xl: '1rem' },
          cursor: enabled ? 'pointer' : 'not-allowed',
          color: enabled ? 'primary' : 'gray',
          // whiteSpace: 'nowrap',  // Отключаем перенос
          // overflow: 'visible',
        }}
      >
        {/*//@ts-ignore*/}
        {t(text)}
      </Button>
    </Box>
  );
};
