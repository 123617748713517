import React, {useMemo} from 'react'
import {Box, Collapse} from '@mui/material'
import {TEnvelopeId} from '5-entities/envelope'

type ParentProps = {
    id: TEnvelopeId
    isExpanded: boolean
    parent: React.ReactNode
    children?: React.ReactNode[]
    onExpandToggle: (id: TEnvelopeId) => void
}

export const Parent = React.forwardRef<HTMLDivElement, ParentProps>(
    (props, ref) => {
        const {
            id,
            isExpanded,
            parent,
            children,
            onExpandToggle,
            ...rest
        } = props

        const hasChildren = !!children && children.length > 0

        // const memoChildren = useMemo(() => {
        //     return children
        // }, [children])

        return (
            <Box
                sx={{
                    position: 'relative',
                    background: 'background.paper',
                    borderBottom: `0.5px solid black`,
                    borderColor: 'divider',
                    '&:last-child': {border: 0},
                }}
                ref={ref}
                {...rest}
            >

                {parent}

                {hasChildren && (
                    <Collapse in={isExpanded}>
                        <Box borderBottom={2} borderColor={'divider'}>
                            {/*{memoChildren}*/}
                            {children}
                        </Box>
                    </Collapse>
                )}
            </Box>
        )
    }
)
