import useTableDefaultTemplate, {TemplatedGroup} from "../table-template";
import {envelopeModel, TEnvelopeId, TEnvNode, TGroupNode} from "../../../5-entities/envelope";
import {useCallback, useEffect, useMemo, useRef, useState} from "react";
import {balances} from "../../../5-entities/envBalances";
import {useAppSelector} from "../../../store";
import {getDisplayCurrency} from "../../../5-entities/currency/displayCurrency/model";
import {fxRateModel} from "../../../5-entities/currency/fxRate";
import {TFxAmount, TISOMonth} from "../../../6-shared/types";
import {addFxAmount} from "../../../6-shared/helpers/money";
import {
    getStructureWithoutGroups,
} from "../../../5-entities/envelope/getEnvelopes";

export const useEnvelopeGroup = (months: TISOMonth[]) => {
    const {defaultPLTemplateStructure, findGroupById} = useTableDefaultTemplate()

    const envGroups = defaultPLTemplateStructure.filter((group: TemplatedGroup) => group.editable_source);
    const [envGroupsStructure, setEnvGroupsStructure] = useState(envGroups);

    const envelopes = envelopeModel.useEnvelopes();

    const envelopesMetricsById = balances.useEnvDataRocketById();
    const envelopesMetricsByMonth = balances.useEnvDataRocket()

    const currency = useAppSelector(getDisplayCurrency);
    const convert = useAppSelector(fxRateModel.converter);

    const structure: (TEnvNode | TGroupNode)[] = useAppSelector(getStructureWithoutGroups);

    const initializeEnvGroupsStructure = useCallback(() => {
        const noGroupSet = new Set(structure);
        const locEnvGroups = envGroupsStructure.map(group => ({ ...group, values_source: [] })); // Создаем локальную копию envGroups

        locEnvGroups.forEach(group => {
            structure.forEach(env => {
                if (env.group === group.title || (env.group === "Долги" && group.id === "outside_operating_expenses")) {
                    // @ts-ignore
                    group.values_source.push(env);
                    noGroupSet.delete(env);
                }
            });
        });
        const uniqueNoGroup = Array.from(noGroupSet);
        // @ts-ignore
        locEnvGroups.find(gr => gr.id === 'hidden')?.values_source.push(...uniqueNoGroup);
        setEnvGroupsStructure(locEnvGroups);
    }, [envGroupsStructure, structure]);

    useEffect(() => {
        initializeEnvGroupsStructure();
    }, []);


    const getEnvRocketByIdRaw = useCallback((id: string) => {
        return envelopesMetricsById[id];
    }, [envelopesMetricsById]);

    const getEnvTransactionsRocketById = useMemo(() => {
        return (id: string) => {
            const transactions = getEnvRocketByIdRaw(id);

            if (!transactions) {
                return {};
            }

            return Object.fromEntries(
                Object.entries(transactions)
                    // @ts-ignore
                    .filter(([date]) => months.includes(date))
            );
        };
    }, [getEnvRocketByIdRaw, months]);

    const getEnvelopeById = useCallback((id: string) => {
        // @ts-ignore
        return envelopes[id];
    }, [envelopes]);

    const currConvert = useCallback((a: TFxAmount, month: TISOMonth) => {
        return convert(a, currency, month);
    }, [convert, currency]);

    const moveEnvelope = useCallback((envelopeId: TEnvelopeId, fromGroupId: string, toGroupId: string) => {
        setEnvGroupsStructure(prevState => {
            const newState = [...prevState];
            // @ts-ignore
            const findAndRemoveEnvelope = (sources, id) => {
                for (let i = 0; i < sources.length; i++) {
                    const source = sources[i];
                    if (source.id === id) {
                        const [removedEnvelope] = sources.splice(i, 1);
                        return removedEnvelope;
                    }
                    if (source.children && source.children.length) {
                        // @ts-ignore

                        const foundInChildren = findAndRemoveEnvelope(source.children, id);
                        if (foundInChildren) return foundInChildren;
                    }
                }
                return null;
            };

            const fromGroup = findGroupById(fromGroupId, newState);
            const toGroup = findGroupById(toGroupId, newState);

            if (!fromGroup || !toGroup) {
                console.error('Invalid fromGroupId or toGroupId');
                return prevState;
            }

            const movedEnvelope = findAndRemoveEnvelope(fromGroup.values_source, envelopeId);

            if (!movedEnvelope) {
                console.error('Envelope not found');
                return prevState;
            }

            toGroup.values_source.push(movedEnvelope);

            return newState;
        });
        // updateStructure();
    }, [findGroupById, setEnvGroupsStructure]);

    //GET TOTALS FOR GROUPS WITH ENVELOPES
    const getGroupTotal = useCallback((id: string) => {
        // Функция для расчета тоталов по заданной группе без учета подгрупп

        const innerCalcFn = (groupId: string) => {
            const group = findGroupById(groupId, envGroupsStructure);
            const envelopesInGroup = group?.values_source ?? [];

            // Рассчитываем общую активность для группы по каждому месяцу
            return months.map(month => {
                const activitySum = envelopesInGroup.reduce(
                    (sum, node) => {
                        const nodeMetrics = envelopesMetricsByMonth[month]?.[node.id];
                        sum.activity = addFxAmount(sum.activity ?? {}, nodeMetrics?.totalActivity ?? 0);
                        return sum;
                    },
                    { activity: {} }
                );
                return { date: month, totalActivity: activitySum.activity };
            });
        };

        // Считаем тоталы для основной группы
        let groupTotals = innerCalcFn(id);

        // Если включен флаг calculate_totals и есть подгруппы
        const group = findGroupById(id, envGroupsStructure);
        if (group?.calculate_totals && group.sub_group) {
            group.sub_group.forEach(sub => {
                const subGroupTotals = innerCalcFn(sub.id);

                // Добавляем активности подгрупп к активности основной группы по каждому месяцу
                groupTotals = groupTotals.map((total, index) => ({
                    date: total.date,
                    totalActivity: addFxAmount(total.totalActivity, subGroupTotals[index].totalActivity)
                }));
            });
        }

        // Конвертируем и возвращаем окончательный результат
        return groupTotals.map(total => ({
            date: total.date,
            totalActivity: currConvert(total.totalActivity, total.date)
        }));
    }, [findGroupById, envGroupsStructure, months, currConvert, envelopesMetricsByMonth]);




    const [groupTotals, setGroupTotals] = useState<{id: string, values: {date: string, totalActivity: number}[]}[]>([]);

    const groupIds = useMemo(() => {
        const ids: string[] = [];
        envGroupsStructure.forEach(group => {
            ids.push(group.id);
            if (group.sub_group) {
                group.sub_group.forEach(subGroup => {
                    ids.push(subGroup.id);
                });
            }
        });
        return ids;
    }, [envGroupsStructure]);

// Обновляем все тоталы разом
    useEffect(() => {
        const newTotals = groupIds.map(id => ({
            id,
            values: getGroupTotal(id)
        }));
        setGroupTotals(newTotals);
    }, [groupIds, getGroupTotal]);



    const restoreEnvGroups = useCallback((data: TemplatedGroup[]) => {
            setEnvGroupsStructure(data);
    }, []);



    const getEnvelopeGroupStructure = useCallback((id:string) => {
        return findGroupById(id, envGroupsStructure)
    }, [envGroupsStructure, findGroupById]);

    return {envGroupsStructure, restoreEnvGroups, getEnvelopeById, getGroupTotal, moveEnvelope, currConvert,initializeEnvGroupsStructure, getEnvTransactionsRocketById, getEnvelopeGroupStructure, groupTotals};
};