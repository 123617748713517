import React, { FC } from 'react'
import { formatMoney } from '6-shared/helpers/money'
import { TFxCode } from '6-shared/types'
import {margin} from "@mui/system";

export type AmountProps = React.DetailedHTMLProps<
  React.HTMLAttributes<HTMLSpanElement>,
  HTMLSpanElement
> & {
  value: number
  isPercent?: boolean
  currency?: TFxCode
  sign?: boolean
  noShade?: boolean
  decimals?: number
  decMode?: 'always' | 'ifOnly' | 'ifAny'
  intProps?: React.HTMLProps<HTMLSpanElement>
  decProps?: React.HTMLProps<HTMLSpanElement>
}

const decStyle = { opacity: 0.5 }

export const Amount: FC<AmountProps> = props => {
  const {
    value = 0,
    currency,
    sign,
    noShade = false,
    decimals = 2,
    decMode = 'always',
    intProps,
    decProps,
    isPercent,
    ...rest
  } = props



  let dec = decimals
  if (decMode === 'always') dec = decimals
  else if (decMode === 'ifOnly')
    dec = value !== 0 && value < 1 && value > -1 ? decimals : 0
  else if (decMode === 'ifAny') dec = value % 1 ? decimals : 0
  else throw Error('Unknown decMode ' + decMode)


  if (isPercent) {
    const formatteedd = (value * 100 > 10) || (value * 100 < -10) ? (value * 100).toFixed(0) : (value * 100).toFixed(2)
    return (
        <span {...rest}>
        <span {...intProps} style={{marginRight: '4px'}}>{formatteedd}</span>
        <span style={noShade ? undefined : decStyle} {...decProps} >
           %
        </span>
      </span>
    )
  }

  let str = ''
  if (value === 0) str = formatMoney(0, currency, dec)
  if (value < 0)
    str = (sign === false ? '' : '−') + formatMoney(-value, currency, dec)
  if (value > 0) str = (sign ? '+' : '') + formatMoney(value, currency, dec)
  const arr = str.split(',')
  if (arr.length === 2) {
    return (
      <span {...rest}>
        <span {...intProps}>{arr[0]},</span>
        <span style={noShade ? undefined : decStyle} {...decProps}>
          {arr[1]}
        </span>
      </span>
    )
  }
  return <span {...rest}>{str}</span>
}
