import { useAppSelector } from 'store/index'
import { checkRaw } from './filtering'
import {compareTrDates, compareTrDatesWithRocket, getType, isViewed} from './helpers'
import { makeTransaction } from './makeTransaction'
import {
  getHistoryStart,
  getSortedTransactions,
  getTransactions,
  getTransactionsHistory, getTransactionsHistoryRocket,
} from './model'
import {
  deleteTransactions,
  deleteTransactionsPermanently,
  markViewed,
  restoreTransaction,
  splitTransfer,
  applyChangesToTransaction,
  recreateTransaction,
  bulkEditTransactions,
} from './thunks'

export type { TransactionPatch } from './thunks'
export type { TrCondition } from './filtering'
export { TrType } from './helpers'

export const trModel = {
  // Selectors
  getTransactions,
  getSortedTransactions,
  getTransactionsHistory,
  getHistoryStart,
  getTransactionsHistoryRocket,

  // Hooks
  useTransactions: () => useAppSelector(getTransactions),
  useSortedTransactions: () => useAppSelector(getSortedTransactions),
  useTransactionsHistory: () => useAppSelector(getTransactionsHistory),
  useTransactionsHistoryRocket: () => useAppSelector(getTransactionsHistoryRocket),

  // Helpers
  compareTrDates,
  compareTrDatesWithRocket,
  makeTransaction,
  getType,
  isViewed,

  //Filtering
  checkRaw,

  // Thunks
  deleteTransactions,
  deleteTransactionsPermanently,
  markViewed,
  restoreTransaction,
  splitTransfer,
  applyChangesToTransaction,
  recreateTransaction,
  bulkEditTransactions,
}
