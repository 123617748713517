import React from 'react'
import {NavBarDesktopNew} from "./NavBarDesktopNew";
import {Theme, useMediaQuery} from "@mui/material";
import {MobileNavNew} from "./NavBarMobileNew";

interface SubItem {
  text: string; // Текст подпункта
  path: string; // Путь для навигации
  enable: boolean; // Активность пункта
}

export interface MenuRoute {
  section: string; // Название раздела
  text: string; // Текст раздела
  enable: boolean; // Активность раздела
  subItems: SubItem[]; // Массив подпунктов
  path?: string; // Путь для навигации
}

export const navigationRoutes:MenuRoute[] = [
  {
    section: 'operations',
    text: 'operations',
    enable: true,
    subItems: [
      {
        text: 'financial_operations',
        path: '/operations/financial',
        enable: true
      },
      {text: 'orders_shipments', path: '/operations/orders', enable: false},
      {text: 'manual_operations', path: '/operations/manual', enable: false},
      {text: 'assets_amortization', path: '/operations/assets', enable: false}
    ]
  },
  {
    section: 'planning',
    text: 'planning',
    enable: true,
    subItems: [
      {text: 'budget_dds', path: '/planning/budget-cf', enable: true},
      {text: 'budget_pl', path: '/planning/budget-pl', enable: false},
      {text: 'payment_calendar', path: '/planning/calendar', enable: false}
    ]
  },
  {
    section: 'reports',
    text: 'reports',
    enable: true,
    subItems: [
      {text: 'dds', path: '/reports/cash-flow', enable: true},
      {text: 'pl_1', path: '/reports/pl1', enable: true},
      {text: 'pl_2', path: '/reports/pl2', enable: true},
      {text: 'balance_report', path: '/reports/balance', enable: false},
      {text: 'create_custom_report', path: '/reports/custom', enable: false}
    ]
  },
  {
    section: 'analytics',
    text: 'analytics',
    enable: true,
    subItems: [
      {text: 'su', path: '/analytics/su', enable: true},
      {text: 'ou', path: '/analytics/ou', enable: false},
      {
        text: 'deal_profitability',
        path: '/analytics/deal-profitability',
        enable: false
      },
      {text: 'create_custom_analytics', path: '/analytics/custom', enable: false}
    ]
  },
];
export const settingsItems:MenuRoute = {
  section: 'help',
  text: 'help',
  enable: true,
  subItems: [
    // {text: 'settings', path: '/help/settings', enable: false},
    {text: 'guides', path: '/help/guides', enable: false},
    {text: 'integrations', path: '/help/integrations', enable: false},
    {text: 'currency_rates', path: '/help/currency-rates', enable: false},
    {text: 'validation', path: '/help/validation', enable: false},
    {text: 'manual_import', path: '/help/import', enable: false},
    {text: 'operation_rules', path: '/help/rules', enable: false}
  ]
}

export default function NavigationMenu() {
  const isMobile = useMediaQuery<Theme>(theme => theme.breakpoints.down('md'))
  return (
    isMobile ? <MobileNavNew/> : <NavBarDesktopNew/>
  )
}

