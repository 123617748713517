import React, {FC} from 'react'
import {Box, Button, Typography} from '@mui/material'
import {useTranslation} from 'react-i18next'
import {ChevronDownIcon} from '6-shared/ui/Icons'
import {TDateDraft, TISOMonth} from '6-shared/types'

import {TableRow, useIsSmall} from '../shared/shared'
import {TableMenu1, useTableMenu1} from './TableMenu1'
import MonthMultiSelect from "./MonthMultiSelect";
import {formatDate} from "../../../../6-shared/helpers/date";
import {Btn} from "../Row/Btn";
import {BreadCrumbs} from "../../../../6-shared/ui/BreadCrumbs";

type HeaderProps = {
  columns: TISOMonth[];
  isAllShown: boolean
  isReordering: boolean
  onShowAllToggle: () => void
  onReorderModeToggle: () => void
  onMonthsChange: (selectedMonths: TISOMonth[]) => void;
  onMonthNameClick: (name: string) => void
}

export const Header: FC<HeaderProps> = props => {
  const {
    columns,
    isAllShown,
    isReordering,
    onShowAllToggle,
    onReorderModeToggle,
    onMonthsChange,
    onMonthNameClick
  } = props
  const {t} = useTranslation('common')
  const openOnClick = useTableMenu1({
    isAllShown,
    isReordering,
    onShowAllToggle,
    onReorderModeToggle,
  })

  const ColumnTitle: FC<{ name: string, onClick: () => void }> = (
    {
      name,
      onClick
    }
  ) => (

    <Box
      display="flex"
      justifyContent="center"
    >
      <Btn onClick={onClick}>
        <Typography
          variant="overline"
          color="text.secondary"
          align="center"
          onClick={onClick}
          noWrap
        >
          {name}
        </Typography>
      </Btn>
    </Box>
  );

  function getMonthName(month: TDateDraft) {
    return formatDate(month, 'LLL').toUpperCase().slice(0, 3)
  }

  function getYear(month: TDateDraft) {
    return new Date(month).getFullYear()
  }

//@ts-ignore
  const formattedDate = (month) => `${getYear(month)} ${getMonthName(month)} `
  return (
    <>
      <Box
        sx={{
          borderTopLeftRadius: 8,
          borderTopRightRadius: 8,
          position: 'sticky',
          top: 0,
          borderBottom: `1px solid`,
          borderColor: 'divider',
          bgcolor: 'background.paper',
          zIndex: 99,
        }}
      >
        <Box
          sx={{
            display: 'flex',
            px: 2,
            gap: 2,
            justifyContent: 'space-between',
            alignItems: 'center',
            width: 'calc(100vw - 40px)',
            flexWrap: 'wrap',
          }}
        >
          <BreadCrumbs/>
          <MonthMultiSelect
            selectedMonths={columns}
            //@ts-ignore
            onChange={onMonthsChange}
          />
        </Box>

        <TableRow
          sx={{
            '&:hover': {
              backgroundColor: 'background.paper',
              transition: '0.1s'
            },
          }}
          name={
            <div>
              <Button
                size="small"
                onClick={openOnClick}
                sx={{ml: 2, px: 1, py: 0,}}
              >
                <Typography variant="overline" color="text.secondary" noWrap>
                  {t('categories', {
                    ns: 'budgets',
                    context: isAllShown ? 'all' : '',
                  })}
                </Typography>
                <ChevronDownIcon/>
              </Button>
            </div>
          }

          columns={columns.map(col => (
            <ColumnTitle
              name={formattedDate(col)}
              key={col}
              onClick={() => onMonthNameClick(col)}
            />
          ))}
        />
      </Box>

      <TableMenu1/>
    </>
  )
}
