import React, {FC, useMemo} from 'react'
import {Box, ButtonBase, Collapse, Typography} from '@mui/material'
import {TableRow} from './shared/shared'
import {Amount} from '6-shared/ui/Amount'
import {useTranslation} from 'react-i18next'
import {ExpandActionButton} from "../../FlowOfFunds/EnvelopeTable/ExpandActionButton";
import {TemplatedGroup} from "../table-template";

type TFormulaGroupProps = {
    group: TemplatedGroup
    children?: React.ReactNode[]
    isSubgroup?: boolean
    isEditMode?: boolean
    onGroupNameClick: () => void
    getGroupValues: (id: string) => number[]
    toggleExpand: (id: string, isExpanded: boolean) => void
    isExpanded: boolean
}
export const FormulaGroup: FC<TFormulaGroupProps> = ({
                                                         group,
                                                         isSubgroup,
                                                         children,
                                                         isEditMode,
                                                         onGroupNameClick,
                                                         getGroupValues,
                                                         isExpanded,
                                                         toggleExpand
                                                     }) => {
    const {t} = useTranslation('budgets')

    // const [isExpanded, setIsExpanded] = useState(true)

    // const {getGroupName,  getFormulaValues } = useTemplateEnvelopes()
    const id = useMemo(() => group.id, [group.id])
    const name = group.title
    const values = useMemo(() => getGroupValues(id), [getGroupValues, id])
    // console.log("(FormulaGroup.tsx:33) ---> values:",name, values);


    const GroupNameCell = (
        <Box
            sx={{
                pl: isSubgroup ? 2 : 0,
                pb: 0,
                minWidth: 0,
                display: 'flex',
                flexDirection: 'row',
                alignContent: 'flex-end',
                justifyContent: 'flex-start',
                alignItems: 'center',
                flexGrow: 1
            }}
        >
            {!!children ? <ExpandActionButton
                    isExpanded={isExpanded}
                    toggleExpanded={e => toggleExpand(id, e)}/>
                :
                <Box sx={{width: '28px'}}/>}
            <ButtonBase
                sx={{p: 1, ml: -1, minWidth: 0, flexShrink: 1, justifyContent: 'flex-start', width: '100%'}}
                onClick={onGroupNameClick}
            >
                <Typography variant="h6" sx={{fontWeight: 600, fontSize: '1rem'}} noWrap>
                    {name}
                </Typography>
            </ButtonBase>
        </Box>
    )
    const isPercent = id.includes('share')


    const Sum: FC<{ value: number }> = React.memo(({value}) => {
        return (
            <Typography alignSelf={'center'} color="text.primary" component="span"
                        variant="body1" align="center" noWrap
                        style={{
                            fontWeight: '500',
                            fontSize: '1rem'
                        }}>
                <Amount value={value} decMode="ifAny" isPercent={isPercent}/>
            </Typography>

        )
    }, (prevProps, nextProps) => prevProps.value === nextProps.value);

    // console.log(`formula group ${id} render`)
    return (
        <>
            <TableRow
                sx={{
                    alignItems: 'center',
                    borderBottom: 'divider',
                }}
                name={GroupNameCell}
                editor={isEditMode && <Box sx={{m: 0, width: 200}}/>}

                columns={
                    // @ts-ignore
                    values.map(({date, totalActivity}) => {
                            return <Sum
                                key={`${date}-${id}-formula`}
                                value={totalActivity}
                                // onClick={e => openTransactionsPopover(activity.ids)}
                            />
                        }
                    )
                }
            />
            <Box
                borderBottom={!isExpanded ? 2 : 0}
                borderTop={isExpanded ? 2 : 0}
                borderColor={'divider'}>
                <Collapse in={isExpanded}>
                    {children}
                </Collapse>
            </Box>
        </>
    )
}

