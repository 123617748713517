import React, {FC, useCallback, useMemo, useRef, useState} from 'react'
import {Box, ButtonBase, Collapse, Typography} from '@mui/material'

import {TableRow} from './shared/shared'
import {TISOMonth} from '6-shared/types'
import {Amount} from '6-shared/ui/Amount'
import {useTranslation} from 'react-i18next'
import {ExpandActionButton} from "../../FlowOfFunds/EnvelopeTable/ExpandActionButton";
import {TemplatedGroup, TemplatedValues} from "../table-template";
import {AmountInput} from "../../../6-shared/ui/AmountInput";

type TEditableGroupProps = {
    group: TemplatedGroup
    children?: React.ReactNode[]
    isSubgroup?: boolean
    isEditMode: boolean
    onGroupNameClick: () => void
    getGroupValues: (id: string) => TemplatedValues[]
    onValueChange: (id: string, date: TISOMonth, value: number) => void
    toggleExpand: (id: string, isExpanded: boolean) => void
    isExpanded: boolean
}
export const EditableGroup: FC<TEditableGroupProps> = ({
                                                           group,
                                                           isEditMode,
                                                           children,
                                                           isSubgroup,
                                                           onGroupNameClick,
                                                           getGroupValues,
                                                           onValueChange,
                                                           isExpanded,
                                                           toggleExpand
                                                       }) => {

    // const [isExpanded, setIsExpanded] = useState(true)

    const id = group.id
    // const id = useMemo(() => group.id, [group.id]);
    const values = useMemo(() => getGroupValues(id) ?? [], [getGroupValues, id]);
    // const values = getGroupValues(id) ?? []
    const name = group.title

    const GroupNameCell = (
        <Box
            sx={{
                pl: isSubgroup ? 2 : 0,
                pb: 0,
                minWidth: 0,
                display: 'flex',
                flexDirection: 'row',
                alignContent: 'flex-end',
                justifyContent: 'flex-start',
                alignItems: 'center',
                flexGrow: 1
            }}
        >
            {!!children ? <ExpandActionButton
                    isExpanded={isExpanded}
                    toggleExpanded={e => toggleExpand(id, e)}/>
                :
                <Box sx={{width: '28px'}}/>}
            <ButtonBase
                sx={{p: 1, ml: -1, minWidth: 0, flexShrink: 1, justifyContent: 'flex-start', width: '100%'}}
                onClick={onGroupNameClick}
            >
                <Typography variant="h6" sx={{fontWeight: 600, fontSize: '1rem'}} noWrap>
                    {name}
                </Typography>
            </ButtonBase>
        </Box>
    )

    interface SumProps {
        initialValue: number
        isEditMode: boolean
        month: TISOMonth
    }

    const Sum: FC<SumProps> = React.memo(({initialValue, isEditMode, month}) => {

        const [localValue, setLocalValue] = useState(initialValue);

        const handleChange = (newVal: number) => setLocalValue(newVal);

        const handleBlur = () => {
            if (localValue !== initialValue) onValueChange(id, month, localValue)
        };

        return (
            isEditMode && !group.calculate_totals ?

                <AmountInput value={localValue} onChange={handleChange} onBlur={handleBlur}/>

                :
                <Typography alignSelf={'center'} color="text.primary" component="span"
                            variant="body1" align="center" noWrap
                            style={{
                                fontWeight: '500',
                                fontSize: '1rem'
                            }}>
                    <Amount value={localValue} decMode="ifOnly"/>
                </Typography>

        )
    })


    const Field: FC<{ children: React.ReactNode, values: TemplatedValues[] }> = React.memo(({children, values}) => {

        return (
            <>
                <TableRow
                    sx={{
                        alignItems: 'center',
                        borderBottom: 'divider',
                    }}
                    name={GroupNameCell}
                    editor={isEditMode && <Box sx={{m: 0, width: 200}}/>}

                    columns={
                        values.map(({date, totalActivity}) => (
                            <Sum
                                key={`group-${id}-sum-${date}`}
                                // @ts-ignore
                                initialValue={totalActivity}
                                isEditMode={isEditMode}
                                month={date}
                            />
                        ))
                    }
                />
                <Box
                    borderBottom={!isExpanded ? 2 : 0}
                    borderTop={isExpanded ? 2 : 0}
                    borderColor={'divider'}>
                    <Collapse in={isExpanded}>
                        {children}
                    </Collapse>
                </Box>
            </>
        )
    }, (prevProps, nextProps) => prevProps.values === nextProps.values);


    return <Field children={children} values={values}/>
}
