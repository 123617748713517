import React from 'react';
import {Box, Theme, Typography, useMediaQuery} from '@mui/material';
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import {MenuRoute, navigationRoutes} from "3-widgets/Navigation";
import ChevronRightOutlinedIcon from '@mui/icons-material/ChevronRightOutlined';
const routes: MenuRoute[] = navigationRoutes

export const BreadCrumbs: React.FC = () => {
  const location = useLocation();
  const { t } = useTranslation('newNav');
  const path = location.pathname;
  const isMobile = useMediaQuery<Theme>(theme => theme.breakpoints.down('md'))
  // const fontSize

  const getBreadcrumbs = () => {
    for (const route of routes) {
      for (const subItem of route.subItems) {
        if (subItem.path === path) {
          return { section: route.text, subItem: subItem.text };
        }
      }
    }
    return null;
  };

  const breadcrumbs = getBreadcrumbs();

  if (!breadcrumbs) return null;

  return (
    <Box display="flex" alignItems="center" p={1}>
      <Typography variant="body2" color="text.secondary" fontSize={isMobile ? 'medium' : 'large'}>
        {/*// @ts-ignore*/}
        {t(breadcrumbs.section)}
      </Typography>
      <ChevronRightOutlinedIcon fontSize={isMobile ? 'small' : 'medium'}/>
      <Typography variant="body2" color="text.primary" fontSize={isMobile ? 'medium' : 'large'}>
        {/*// @ts-ignore*/}
        {t(breadcrumbs.subItem)}
      </Typography>
    </Box>
  );
};
