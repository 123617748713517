import { shallowEqual } from 'react-redux'
import { createSelector } from '@reduxjs/toolkit'
import { ById } from '6-shared/types'
import { withPerf } from '6-shared/helpers/performance'
import { keys } from '6-shared/helpers/keys'

import { TSelector } from 'store'
import { accountModel } from '5-entities/account'
import { debtorModel } from '5-entities/debtors'
import { tagModel } from '5-entities/tag'
import { userModel } from '5-entities/user'

import { makeEnvelope, TEnvelope } from './shared/makeEnvelope'
import { getEnvelopeMeta } from './shared/metaData'
import { buildStructure, flattenStructure } from './shared/structure'
import { TEnvelopeId } from './shared/envelopeId'
import {useEffect, useRef, useState} from "react";
import {envelopeModel} from "./index";

const getCompiledEnvelopes: TSelector<{
  byId: ById<TEnvelope>
  structure: ReturnType<typeof buildStructure>
}> = createSelector(
  [
    debtorModel.getDebtors,
    tagModel.getPopulatedTags,
    accountModel.getSavingAccounts,
    getEnvelopeMeta,
    userModel.getUserCurrency,
  ],
  withPerf(
    'getCompiledEnvelopes',
    (debtors, tags, savingAccounts, envelopeMeta, userCurrency) => {
      let envelopes: ById<TEnvelope> = {}

      // Step 1. Create envelopes from tags, saving accounts and debtors
      Object.values(tags).forEach(tag => {
        const e = makeEnvelope.tag(tag, envelopeMeta, userCurrency)
        envelopes[e.id] = e
      })
      savingAccounts.forEach(account => {
        const e = makeEnvelope.account(account, envelopeMeta, userCurrency)
        envelopes[e.id] = e
      })
      Object.values(debtors).forEach(debtor => {
        const e = makeEnvelope.debtor(debtor, envelopeMeta, userCurrency)
        envelopes[e.id] = e
      })

      // Step 2. Build a valid structure

      const structure = buildStructure(envelopes)

      // Step 3. Apply parameters from structure to envelopes
      flattenStructure(structure).forEach((node, index) => {
        // Skip groups
        if (node.type === 'group') return
        // Update envelope props from valid structure
        const envelope = envelopes[node.id]
        envelope.parent = node.parent
        envelope.group = node.group
        envelope.children = node.children.map(child => child.id)
        envelope.index = index // Update indicies
      })
        // console.log("(getEnvelopes.ts:53) ---> result envelopes:", envelopes);
        // console.log("(getEnvelopes.ts:53) ---> result structure:", structure);
      return { byId: envelopes, structure }
    }
  )
)



export const getEnvelopes: TSelector<ById<TEnvelope>> = state =>
  getCompiledEnvelopes(state).byId

export const getEnvelopeStructure: TSelector<
  ReturnType<typeof buildStructure>
> = state => getCompiledEnvelopes(state).structure

export const getStructureWithoutGroups = createSelector(
  [getEnvelopeStructure],
  structure => flattenStructure(structure).filter(node => node.type !== 'group').filter(env => !env.parent),
)

/** List of envelopes that keep their income */
export const getKeepingEnvelopes: TSelector<TEnvelopeId[]> = createSelector(
  [getEnvelopes],
  envelopes => keys(envelopes).filter(id => envelopes[id].keepIncome),
  { memoizeOptions: { resultEqualityCheck: shallowEqual } }
)

export const getEnvelopesWithIncome: TSelector<TEnvelopeId[]> = createSelector(
  [getEnvelopes],
  envelopes => keys(envelopes).filter(id => envelopes[id]),
  { memoizeOptions: { resultEqualityCheck: shallowEqual } }
)

// export function useTrackEnvelopeChanges() {
//     const envelopes = envelopeModel.useEnvelopes(); // Получаем актуальные данные
//     const prevEnvelopesRef = useRef(envelopes); // Храним предыдущее состояние envelopes
//     const [changes, setChanges] = useState([]);
//
//     useEffect(() => {
//         const prevEnvelopes = prevEnvelopesRef.current;
//         const newChanges = [];
//
//         Object.keys(envelopes).forEach(tagId => {
//             // @ts-ignore
//             const oldEnv = prevEnvelopes[tagId];
//             // @ts-ignore
//             const newEnv = envelopes[tagId];
//
//             if (oldEnv) {
//                 const changeRecord = { tagId, changes: {} };
//
//                 // Проверка изменения индекса
//                 if (oldEnv.index !== newEnv.index) {
//                     // @ts-ignore
//                     changeRecord.changes.index = { from: oldEnv.index, to: newEnv.index };
//                 }
//
//                 // Проверка изменения родителя
//                 if (oldEnv.parent !== newEnv.parent) {
//                     // @ts-ignore
//                     changeRecord.changes.parent = {
//                         from: oldEnv.parent || "self",
//                         to: newEnv.parent || "self"
//                     };
//                 }
//
//                 // Проверка изменений в children
//                 if (JSON.stringify(oldEnv.children) !== JSON.stringify(newEnv.children)) {
//                     // @ts-ignore
//                     changeRecord.changes.children = {
//                         from: oldEnv.children,
//                         to: newEnv.children
//                     };
//                 }
//
//                 // Если есть изменения, добавляем их в массив
//                 if (Object.keys(changeRecord.changes).length > 0) {
//                     newChanges.push(changeRecord);
//                 }
//             } else {
//                 // Новый объект, которого не было в предыдущем состоянии
//                 newChanges.push({ tagId, changes: { status: "new" } });
//             }
//         });
//
//         // Обновляем состояние при наличии изменений
//         if (newChanges.length > 0) {
//             // @ts-ignore
//             setChanges(prevChanges => [...prevChanges, ...newChanges]);
//         }
//
//         // Обновляем ссылку на предыдущее состояние
//         prevEnvelopesRef.current = envelopes;
//     }, [envelopes]);
//
//     return changes;
// }

