import React, {FC, ReactNode, useCallback, useMemo} from 'react'
import { useDroppable } from '@dnd-kit/core'
import { envelopeModel, TEnvelopeId } from '5-entities/envelope'

import { TableRow } from '../shared/shared'
import { NameCell } from './NameCell'
import { ActivityCell } from './ActivityCell'
import {TISOMonth} from "../../../../6-shared/types";
import {DragTypes} from "../../../Budgets/DnD";
import {ExpandActionButton} from "../ExpandActionButton";

type EnvelopeRowProps = {
  id: TEnvelopeId
  values: any[]
  isSelf?: boolean
  isLastVisibleChild?: boolean
  isExpanded?: boolean
  onExpandToggle?: (id: TEnvelopeId) => void
  isReordering: boolean
  openDetails: (id: TEnvelopeId) => void
  openTransactionsPopover: (id: TEnvelopeId, month: TISOMonth) => void
  isDefaultVisible: boolean
  valuesKey: 'totalActivity' | 'selfActivity'
}

export const Row: FC<EnvelopeRowProps> = props => {
  const {
    id,
    isSelf,
    isReordering,
    openTransactionsPopover,
    openDetails,
    onExpandToggle,
    values,
    isLastVisibleChild,
    isExpanded,
    isDefaultVisible,
    valuesKey
  } = props

  const envelope = envelopeModel.useEnvelopes()[id]

  const isChild = !!envelope.parent || !!isSelf
  const isParent = !!envelope.children

  const isExpandable = isExpanded !== undefined && onExpandToggle && !!envelope.children.length

  const handleNameClick = useCallback(() => {
    openDetails(id)
  }, [id, openDetails])

  const handleExpand = () => {
    if (isExpandable) onExpandToggle(id)
  }

  return  (
    <Droppable
      id={id}
      isChild={isChild}
      isLastVisibleChild={!!isLastVisibleChild}
      isExpanded={!!isExpanded}
    >
      <TableRow
        className={'tableRow'}
        sx={{
          cursor: 'pointer',
          borderColor: 'divider',
          borderBottom: 1,
          '&:hover::before': {
            content: '""',
            position: 'absolute',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            backgroundColor: 'var(--mui-palette-action-hover)',
            zIndex: 3,
            pointerEvents: 'none',
            transition: 'background-color 0.1s ease-in-out',
          },
        }}
        name={
        <>
          {isExpandable && <div style={{position: 'absolute', left: '16px'}}>
            <ExpandActionButton isExpanded={isExpanded}
                                toggleExpanded={e => handleExpand()}/>
          </div>}
          <NameCell
            onClick={handleNameClick}
            envelope={envelope}
            isChild={isChild}
            isParent={isParent}
            isSelf={isSelf}
            isDefaultVisible={isDefaultVisible}
            isReordering={isReordering}
          />
        </>
        }
        columns={
          values.map((activity, index) => (
            <ActivityCell
              key={activity.date}
              value={activity[valuesKey]}
              month={activity.date}
              onClick={e => openTransactionsPopover(id, activity.date)}
            />
          ))
        }
      />
    </Droppable>
  )
}

const Droppable: FC<{
  id: TEnvelopeId
  isChild: boolean
  isLastVisibleChild: boolean
  isExpanded: boolean
  children: ReactNode
}> = props => {
  const { id, isChild, isLastVisibleChild, isExpanded, children } = props
  const { setNodeRef } = useDroppable({
    id: 'envelope-drop' + id + isChild,
    data: { type: DragTypes.envelope, id, isLastVisibleChild, isExpanded },
  })

  return <div ref={setNodeRef}>{children}</div>
}
