import {TISOMonth} from "../../../6-shared/types";
import useTableDefaultTemplate, {TemplatedGroup} from "../table-template";
import {useCallback, useEffect, useMemo, useState} from "react";

export const useEditableGroup = (months: TISOMonth[]) => {

    const {defaultPLTemplateStructure, findGroupById} = useTableDefaultTemplate()

    const editableGroups = defaultPLTemplateStructure.filter((group: TemplatedGroup) => group.editable_values);
    const [editableGroupsStructure, setEditableGroupsStructure] = useState(editableGroups);

    useEffect(() => {
        setEditableGroupsStructure(editableGroups);
    }, []);

    const setValue = useCallback((groupId: string, date: TISOMonth, value: number) => {

        setEditableGroupsStructure((prevState) => {
            const newState = [...prevState];
            const group = findGroupById(groupId, newState);
            if (!group) return prevState;

            // Найти объект с указанной датой в значениях группы
            const found = group.values.find((tr) => tr.date === date);

            if (found) {
                found.totalActivity = value;
            } else {
                group.values.push({date, totalActivity: value});
            }

            return newState;
        });
    }, [findGroupById]);

    const getGroupValues = useCallback((id: string) => {
        // Вспомогательная функция для получения значений группы по ID
        const innerCalcFn = (groupId: string) => {
            const group = findGroupById(groupId, editableGroupsStructure);

            if (!group) return [];

            // Фильтрация значений по месяцам и сортировка
            return group.values
                .filter((value) => months.includes(value.date))
                .sort((a, b) => (a.date > b.date ? 1 : -1));
        };

        // Получаем значения для родительской группы
        let parentGrValues = innerCalcFn(id);

        // Инициализация результатов значениями из родительской группы
        let resultValues = months.map((month) => {
            const existingValue = parentGrValues.find((value) => value.date === month);
            return existingValue ? existingValue : { date: month, totalActivity: 0 };
        });

        // Если calculate_totals включен, добавляем значения подгрупп
        const group = findGroupById(id, editableGroupsStructure);
        if (group?.calculate_totals && group.sub_group) {
            group.sub_group.forEach(sub => {
                const subGroupValues = innerCalcFn(sub.id);

                // Добавляем значения подгрупп к значению родительской группы по каждому месяцу
                resultValues = resultValues.map((result, index) => {
                    const subValue = subGroupValues.find((value) => value.date === result.date);
                    const totalActivity = subValue
                        // @ts-ignore
                        ? result.totalActivity + subValue.totalActivity
                        : result.totalActivity;
                    return { date: result.date, totalActivity };
                });
            });
        }

        return resultValues;
    }, [editableGroupsStructure, findGroupById, months]);



    const getAllGroupValues = useMemo(() => {
        // @ts-ignore
        const res = [];
        editableGroupsStructure.forEach(gr => {
            const values = getGroupValues(gr.id);
            if (values.length > 0) {
                res.push({id: gr.id, values});
            }
            if (gr.sub_group) {
                gr.sub_group.forEach(sub => {
                    const subValues = getGroupValues(sub.id);
                    if (subValues.length > 0) {
                        res.push({id: sub.id, values: subValues});
                    }
                });
            }
        });
        // @ts-ignore
        return res;
    }, [editableGroupsStructure, getGroupValues]);

    const saveInLsEditable = useCallback(() => {
        localStorage.setItem('editableGroupsStructure', JSON.stringify(editableGroupsStructure));
    }, [editableGroupsStructure]);

    const loadFromLsEditable = useCallback(() => {
        const data = localStorage.getItem('editableGroupsStructure');
        if (data) {
            setEditableGroupsStructure(JSON.parse(data));
        }
    }, []);

    const restoreEditableGroups = useCallback((data: TemplatedGroup[]) => {
        setEditableGroupsStructure(data);
    }, [setEditableGroupsStructure]);


    return {editableGroupsStructure, restoreEditableGroups, getGroupValues, setValue, getAllGroupValues, saveInLsEditable, loadFromLsEditable}
}