import {apiBaseUrl} from "../../config";
import {TISOMonth} from "../../types";
import {TemplatedGroup} from "../../../2-pages/PNLReport2/table-template";

export interface PL2SavedData {
    userId: number;
    editable_structure: TemplatedGroup[];
    envelope_structure: TemplatedGroup[];
    months: TISOMonth[];
    expanded_rows: string[];
}


export async function getPL2Data(userId: number): Promise<PL2SavedData> {
    const response = await fetch(`${apiBaseUrl}/pl2-data/${userId}`);
    if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
    }
    return response.json();
}

/**
 * Сохраняет полный набор данных PL2
 */
export async function savePL2Data(
    userId: number,
    data: Omit<PL2SavedData, 'userId'>
): Promise<PL2SavedData> {
    const response = await fetch(`${apiBaseUrl}/pl2-data/${userId}`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
    });
    if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
    }
    return response.json();
}

/**
 * Обновляет только редактируемую структуру
 */
export async function updatePL2EditableStructure(
    userId: number,
    editableStructure: TemplatedGroup[]
): Promise<PL2SavedData> {
    const response = await fetch(`${apiBaseUrl}/pl2-data/${userId}/editable-structure`, {
        method: 'PATCH',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({ editable_structure: editableStructure }),
    });
    if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
    }
    return response.json();
}

/**
 * Обновляет только структуру конвертов
 */
export async function updatePL2EnvelopeStructure(
    userId: number,
    envelopeStructure: TemplatedGroup[]
): Promise<PL2SavedData> {
    const response = await fetch(`${apiBaseUrl}/pl2-data/${userId}/envelope-structure`, {
        method: 'PATCH',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({ envelope_structure: envelopeStructure }),
    });
    if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
    }
    return response.json();
}

/**
 * Обновляет только список месяцев
 */
export async function updatePL2Months(
    userId: number,
    months: string[]
): Promise<PL2SavedData> {
    const response = await fetch(`${apiBaseUrl}/pl2-data/${userId}/months`, {
        method: 'PATCH',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({ months }),
    });
    if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
    }
    return response.json();
}

/**
 * Обновляет только список раскрытых групп\конвертов
 */
export async function updatePL2ExpandedRows(
    userId: number,
    expanded_rows: string[]
): Promise<PL2SavedData> {
    const response = await fetch(`${apiBaseUrl}/pl2-data/${userId}/expanded_rows`, {
        method: 'PATCH',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({ expanded_rows }),
    });
    if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
    }
    return response.json();
}