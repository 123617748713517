import React, {FC} from 'react'
import {Box, Button, IconButton, Typography} from '@mui/material'
import {useTranslation} from 'react-i18next'
import {ChevronDownIcon} from '6-shared/ui/Icons'
import {TDateDraft, TISOMonth} from '6-shared/types'

import {TableRow} from '../shared/shared'
import {TableMenu2, useTableMenu2} from './TableMenu1'
import MonthMultiSelect from "../../../../6-shared/ui/MonthMultiSelect";
import {formatDate} from "../../../../6-shared/helpers/date";
import {Btn} from "../Row/Btn";
import {BreadCrumbs} from "../../../../6-shared/ui/BreadCrumbs";

type HeaderProps = {
    months: any[];
    allMonths: any[]
    isAllShown: boolean
    isReordering: boolean
    onShowAllToggle: () => void
    onReorderModeToggle: () => void
    onMonthNameClick: (name: string) => void
    onMonthsChange: (selectedMonths: TISOMonth[]) => void
}

export const Header: FC<HeaderProps> = props => {
    const {
        months,
        allMonths,
        isAllShown,
        isReordering,
        onShowAllToggle,
        onReorderModeToggle,
        onMonthsChange,
        onMonthNameClick,
    } = props
    const {t} = useTranslation('common')
    const openOnClick = useTableMenu2({
        isAllShown,
        isReordering,
        onShowAllToggle,
        onReorderModeToggle,
    })

    const ColumnTitle: FC<{ name: string, onClick: () => void }> = (
        {
            name,
            onClick
        }
    ) => (

        <Box
            display="flex"
            justifyContent="center"
            sx={{
                bgcolor: 'background.paper',
                zIndex: 99,
            }}
        >
            <Btn onClick={onClick}>
                <Typography
                    variant="overline"
                    color="text.secondary"
                    align="center"
                    onClick={onClick}
                    noWrap
                >
                    {name}
                </Typography>
            </Btn>
        </Box>
    );


    function getMonthName(month: TDateDraft) {
        return formatDate(month, 'LLL').toUpperCase().slice(0, 3)
    }

    function getYear(month: TDateDraft) {
        return new Date(month).getFullYear()
    }

//@ts-ignore
    const formattedDate = (month) => `${getYear(month)} ${getMonthName(month)}`

    // @ts-ignore
    return (
        <>
            <Box display='flex' flexDirection='column'
                 sx={{
                     width: '100%',
                     borderTopLeftRadius: 8,
                     borderTopRightRadius: 8,
                     position: 'sticky',
                     top: 0,
                     borderBottom: `1px solid`,
                     borderColor: 'divider',
                     bgcolor: 'background.paper',
                     zIndex: 99,
                 }}
            >
                <Box
                    sx={{
                        display: 'flex',
                        px: 2,
                        gap: 2,
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        width: 'calc(100vw - 40px)',
                        bgcolor: 'background.paper',
                        zIndex: 99,
                        flexWrap: 'wrap',
                    }}
                >
                    <BreadCrumbs/>
                    <Box display="flex" alignItems='center'>

                        <MonthMultiSelect
                            selectedMonths={months}
                            onChange={onMonthsChange}
                            allMonths={allMonths}
                        />
                    </Box>

                </Box>

                <TableRow
                    sx={{
                        '&:hover': {
                            backgroundColor: 'background.paper',
                            transition: '0.1s'
                        },
                    }}
                    name={
                        <div>
                            <Button
                                size="small"
                                onClick={openOnClick}
                                sx={{ml: 2, px: 1, py: 0}}
                            >
                                <Typography variant="overline" color="text.secondary" noWrap>
                                    {t('categories', {
                                        ns: 'budgets',
                                        context: isAllShown ? 'all' : '',
                                    })}
                                </Typography>
                                <ChevronDownIcon/>
                            </Button>
                        </div>
                    }
                    editor={isReordering && <Box
                        display="flex"
                        justifyContent="center"
                        sx={{m: 0, width: 200}}>
                        <Typography variant="overline" color="text.secondary" noWrap>
                            {'Базовая аналитика'}
                        </Typography>
                    </Box>}

                    columns={months.map(month => (
                        <ColumnTitle
                            name={formattedDate(month)}
                            key={month}
                            onClick={() => onMonthNameClick(month)}
                        />
                    ))}
                />
            </Box>

            <TableMenu2/>
        </>
    )
}
