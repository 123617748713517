import React from 'react';
import {  Drawer, Theme, useMediaQuery } from '@mui/material';

type DrawerWrapperPropsType = {
  isOpen: boolean
  onClose: () => void
  children: React.ReactNode
  position: 'right' | 'left'
  width?: number
}

export const DrawerWrapper = ({
                                isOpen,
                                onClose,
                                children,
                                position,
                                width
                              }: DrawerWrapperPropsType) => {
  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'));
  const navBarHeight = isMobile ? '48px' : '64px';

  const sideWidth = {xs: '100vw', sm: width ? `${width}vw` : '360px'}
  const sideDrawerStyles = {
    paper: {
      top: navBarHeight,
      boxShadow: 'none',
      overflow: 'auto',
      width: sideWidth,
      paddingBottom: '64px',
      transition: 'width 0.3s ease-in-out',
    },
    backdrop: {
      top: navBarHeight,
      zIndex: 998,
      pointerEvents: 'all',
    },
  };

  return (
    <Drawer
      anchor={position}
      sx={{
        '& .MuiDrawer-paper': {
          ...sideDrawerStyles.paper
        },
        top: navBarHeight,
        width: sideWidth,
        transition: 'width 0.3s ease-in-out',
      }}
      open={isOpen}
      onClose={onClose}
      onBackdropClick={onClose}
      BackdropProps={{
        sx: {
          ...sideDrawerStyles.backdrop
        }
      }}
    >
        {children}
    </Drawer>
  );
};
