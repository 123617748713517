import {Theme, useMediaQuery} from '@mui/material'
import {Box, BoxProps, SxProps} from '@mui/system'
import {FC, ReactNode} from 'react'
import styled from "@emotion/styled";

export function useIsSmall() {
    return useMediaQuery<Theme>(theme => theme.breakpoints.down('sm'))
}

const rowStyle: SxProps = {
    display: 'flex', gap: '8px', height: '48px',
    borderBottom: 'divider',
    position: 'relative',

}

const TableColumnName = styled.div<{ widthCss: string }>`
    display: flex;
    align-items: center;
    position: sticky;
    background-color: var(--mui-palette-background-paper);
    z-index: 3;
    left: 0;
    width: ${(props) => props.widthCss};
    border-right: 2px solid;
    border-color: var(--mui-palette-divider);
`;

export const TableRow: FC<
    BoxProps & {
    name: ReactNode
    editor?: ReactNode
    columns?: ReactNode[]
}
> = props => {
    const {name, editor, columns, sx, ...rest} = props
    const isSmall = useIsSmall()
    return (
        <Box sx={sx ? {...rowStyle, ...sx} : rowStyle} {...rest}>

            <TableColumnName
                className={'table-column-name'}
                widthCss={isSmall ? '200px' : '300px'}>
                {name}
            </TableColumnName>
            <div
                className={'table-columns-values'}
                style={{
                    display: 'flex',
                    justifyContent: 'flex-start',
                    left: `${isSmall ? '200px' : '300px'}`,
                    position: 'absolute',
                    height: '48px',
                    alignItems: 'center',
                    zIndex: 2,
                    backgroundColor: 'background.paper',
                    padding: 0,
                    // borderBottom: `1px solid`,
                }}
            >
                {editor && <Box className={'edit'} sx={{
                    padding: 0,
                    margin: 0,
                    borderRight: 1,
                    borderBottom: 1,
                    borderColor: 'divider',
                    bgcolor: 'background.paper',
                    zIndex: 2
                }}
                                style={{
                                    height: '100%',
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                }}>{editor}</Box>}
                {columns && columns.map((col, index) => <Box className={'col'}
                                                  key={`table-row-${index}`}
                                                  sx={{
                                                      padding: 0,
                                                      margin: 0,
                                                      borderRight: 1,
                                                      borderBottom: 1,
                                                      borderColor: 'divider',
                                                      bgcolor: 'background.paper',
                                                      zIndex: 2
                                                  }}
                                                  style={{
                                                      width: '100px',
                                                      height: '100%',
                                                      display: 'flex',
                                                      alignItems: 'center',
                                                      justifyContent: 'center',
                                                  }}>

                    {col}
                </Box>)}
            </div>
        </Box>
    )
}
