import { useAppSelector } from 'store/index'
import { getMonthList } from './1 - monthList'
import {getRawActivity, getRawActivityRocket} from './1 - rawActivity'
import { getActivity } from './2 - activity'
import { getRatesByMonth } from './2 - rates'
import { getSortedActivity } from './2 - sortedActivity'
import {
  getEnvMetricksRocketById,
  getEnvMetrics,
  getEnvMetricsRocket,
  getEnvMetricsWithIncome
} from './3 - envMetrics'
import { getMonthTotals } from './4 - monthTotals'

export type { TRawActivityNode } from './1 - rawActivity'
export type { TActivityNode } from './2 - activity'
export type { TSortedActivityNode, TSortedActivity } from './2 - sortedActivity'
export type { TEnvMetrics } from './3 - envMetrics'
export type { TMonthTotals } from './4 - monthTotals'

export { EnvActivity } from './1 - rawActivity'
export { TrFilterMode } from './2 - sortedActivity'

export const balances = {
  // Selectors
  monthList: getMonthList,
  rates: getRatesByMonth,
  rawActivity: getRawActivity,
  rawActivityRocket: getRawActivityRocket,
  activity: getActivity,
  sortedActivity: getSortedActivity,
  envData: getEnvMetrics,
  totals: getMonthTotals,
  rocketMetrics: getEnvMetricsRocket,
  rocketMetricsById: getEnvMetricksRocketById,

  // Hooks
  useMonthList: () => useAppSelector(getMonthList),
  useRates: () => useAppSelector(getRatesByMonth),
  useRawActivity: () => useAppSelector(getRawActivity),
  useActivity: () => useAppSelector(getActivity),
  useSortedActivity: () => useAppSelector(getSortedActivity),
  useEnvData: () => useAppSelector(getEnvMetrics),
  useEnvDataRocket: () => useAppSelector(getEnvMetricsRocket),
  useEnvDataRocketById: () => useAppSelector(getEnvMetricksRocketById),
  useEnvDataWithIncome: () => useAppSelector(getEnvMetricsWithIncome),
  useTotals: () => useAppSelector(getMonthTotals),
}
