import {IRocketDataItem} from "store/rocketData";
import {apiBaseUrl} from "../../config";
import {toISOMonth} from "../../helpers/date";

export const getTransactionsExtraData = async (transactionIds: string[]) => {
        const chunkSize = 50;

        const chunkArray = (array: string[], size: number): string[][] => {
            const results = [];
            for (let i = 0; i < array.length; i += size) {
                results.push(array.slice(i, i + size));
            }
            return results;
        };

        const transactionChunks = chunkArray(transactionIds, chunkSize);

        try {
            const allResults = await Promise.allSettled(
                transactionChunks.map(async (chunk) => {
                    const response = await fetch(`${apiBaseUrl}/transactions/load-extras`, {
                        method: 'POST',
                        body: JSON.stringify({transactionIds: chunk}),
                        headers: {
                            'Content-Type': 'application/json',
                        },
                    });

                    if (!response.ok) {
                        throw new Error(`Network response was not ok: ${response.statusText}`);
                    }

                    return await response.json();
                })
            );
            return allResults
                .filter((result): result is PromiseFulfilledResult<IRocketDataItem[]> => result.status === 'fulfilled' && result.value !== null)
                .flatMap(result => result.value)
                .map(result => {
                    if (result.pl_date) {
                        result.pl_date = toISOMonth(result.pl_date); // Изменяем pl_date
                    }
                    return result; // Возвращаем изменённый объект
                })

        } catch
            (error) {
            console.error('Failed to populate transactions with Rocket data:', error);
            throw error;
        }
    }

export const getSingleTransactionExtraData = async (id: string) => {
    try {
        const response = await fetch(`${apiBaseUrl}/transactions/load-extra/${id}`);
        if (!response.ok) {
            throw new Error('Network response was not ok: ' + response.statusText);
        }
        return await response.json()
    } catch (error) {
        console.error(`Failed to fetch details for transaction ${id}:`, error);
    }
}
export const saveTransactionsExtraData = async (id: string, payload: any) => {
    try {
        const response = await fetch(`${apiBaseUrl}/transactions/save-extra/${id}`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(payload),
        });

        if (!response.ok) {
            throw new Error('Network response was not ok');
        }

        return await response.json()
    } catch (error) {
        console.error("Failed to save Extra params to custom db:", error);
    }
}


export const collectStructureTransactionsIds = async (operations: any) => {
    let transactionsIds = []

    for (const [date, entry] of Object.entries(operations)) {
        //@ts-ignore
        for (const [categoryId, category] of Object.entries(entry)) {
            //@ts-ignore
            transactionsIds.push(category.totalTransactions.map(tr => tr.id))
        }
    }
    const uniqueTransactionsIds = [...new Set(transactionsIds.flat())];
    return await getTransactionsExtraData(uniqueTransactionsIds)
}




