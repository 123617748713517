import React, {FC, lazy, Suspense, useEffect, useState} from 'react'
import {Redirect, Route, Router, Switch} from 'react-router-dom'
import {createBrowserHistory} from 'history'
import {Box, CircularProgress, Theme, Typography, useMediaQuery,} from '@mui/material'
import {useTranslation} from 'react-i18next'
import {initTracking, setUserId} from '6-shared/helpers/tracking'
import {PopoverManager} from '6-shared/historyPopovers'
import {useAppSelector} from 'store'
import {getLoginState} from 'store/token'
import {getLastSyncTime} from 'store/data/selectors'
import {userModel} from '5-entities/user'
import {RegularSyncHandler} from '3-widgets/RegularSyncHandler'
import ErrorBoundary from '3-widgets/ErrorBoundary'
import Transactions from '2-pages/Transactions'
import Auth from '2-pages/Auth'
import Budgets from '2-pages/Budgets'
import FlowOfFunds from '2-pages/FlowOfFunds'
import PnlFunction from '2-pages/PNLReport'
import Accounts from '2-pages/Accounts'
import {GlobalWidgets} from './GlobalWidgets'
import useFetchRocketData from "../6-shared/api/rocketData/useFetchRocketData";
import NavigationMenu from "3-widgets/Navigation";
import PnlTemplate2 from '2-pages/PNLReport2'


const About = lazy(() => import('2-pages/About'))
const Donation = lazy(() => import('2-pages/Donation'))
const Token = lazy(() => import('2-pages/Token'))
const Stats = lazy(() => import('2-pages/Stats'))
const Review = lazy(() => import('2-pages/Review'))

const history = createBrowserHistory()
initTracking(history)

export default function App() {
    const isLoggedIn = useAppSelector(getLoginState)
    const hasData = useAppSelector(state => !!getLastSyncTime(state))
    const userId = userModel.useRootUserId()
    useEffect(() => {
        if (userId) setUserId(userId)
    }, [userId])
    useFetchRocketData()

    const publicRoutes = [
        <Route key="about" path="/about" component={About}/>,
        <Route key="about/*" path="/about/*" component={About}/>,
        <Route key="donation" path="/donation" component={Donation}/>,
        <Route key="auth0" path="/api/zerro/callback/" component={Auth}/>,
    ]

    const notLoggedIn = [
        ...publicRoutes,
        <Route key="*" path="/*" component={Auth}/>,
    ]

    const loggedInNoData = [
        ...publicRoutes,
        <Route key="token" path="/token" component={Token}/>,
        <Route key="*" path="*" component={MainLoader}/>,
    ]

    const loggedInWithData = [
        ...publicRoutes,
        <Route key="token" path="/token" component={Token}/>,
        <Route key="transactions" path="/operations/financial" component={Transactions}/>,
        <Route key="review" path="/review" component={Review}/>,
        <Route key="accounts" path="/accounts" component={Accounts}/>,
        <Route key="budget" path="/planning/budget-cf" component={Budgets}/>,
        <Route key="cashFlow" path="/reports/cash-flow" component={FlowOfFunds}/>,
        <Route key="pnl1" path="/reports/pl1" component={PnlFunction}/>,
        <Route key="pnl2" path="/reports/pl2" component={PnlTemplate2}/>,
        <Route key="stats" path="/analytics/su" component={Stats}/>,
        <Route key="*" path="*" render={() => <Redirect to="/planning/budget-cf"/>}/>,
    ]

    const getRoutes = () => {
        if (!isLoggedIn) return notLoggedIn
        if (!hasData) return loggedInNoData
        return loggedInWithData
    }

    const routes = getRoutes()

    return (
        <Router history={history}>
            <PopoverManager>
                <RegularSyncHandler/>
                <Layout isLoggedIn={isLoggedIn}>
                    <ErrorBoundary>
                        <Suspense fallback={<FallbackLoader/>}>
                            <Switch>{routes}</Switch>
                        </Suspense>
                    </ErrorBoundary>
                </Layout>
                <GlobalWidgets/>
            </PopoverManager>
        </Router>
    )
}

const Layout: FC<{
    isLoggedIn: boolean
    children: React.ReactNode
}> = props => {
    const {isLoggedIn, children} = props
    const isMobile = useMediaQuery<Theme>(theme => theme.breakpoints.down('md'))
    return (
        <Box display="flex" flexDirection="column">
            {isLoggedIn && (
                <Box position="fixed" top={0} left={0} width="100%" zIndex={1000}>
                    <NavigationMenu/>
                </Box>
            )}
            <Box flexGrow={1}
                 mt={isLoggedIn ? (isMobile ? '48px' : '64px') : '0px'}
                 height={`calc(100dvh - ${isMobile ? '48px' : '64px'})`}
                 overflow='hidden'
            >
                {children}
            </Box>
        </Box>
    )
}


const FallbackLoader = () => (
    <Box sx={{display: 'grid', placeContent: 'center', height: '100%'}}>
        <CircularProgress/>
    </Box>
)

function MainLoader() {
    const [hint, setHint] = useState('')
    const {t} = useTranslation('loadingHints')

    useEffect(() => {
        const hints = [
            {hint: t('hint'), delay: 0},
            {hint: t('hint_1', 'hint'), delay: 5000},
            {hint: t('hint_2', 'hint'), delay: 10000},
            {hint: t('hint_3', 'hint'), delay: 30000},
            {hint: t('hint_4', 'hint'), delay: 45000},
        ]
        const timers = hints.map(({hint, delay}) =>
            setTimeout(() => setHint(hint), delay)
        )
        return () => {
            timers.forEach(timer => clearTimeout(timer))
        }
    }, [t])
    return (
        <Box
            display="flex"
            flexDirection="column"
            alignItems="center"
            justifyContent="center"
            height="100%"
        >
            <CircularProgress/>
            <Box mt={4} width="200">
                <Typography align="center">{hint}</Typography>
            </Box>
        </Box>
    )
}

