import React, {FC, ReactElement, useState} from 'react'
import {Helmet} from 'react-helmet'
import {Box, Paper} from '@mui/material'

import {PnlReport} from './EnvelopeTable'
import {DnDContext} from './DnD'
import {TTransaction} from "../../6-shared/types";
import {PNLTransactionListDrawer} from "./EnvelopeTable/Sidebar/SidebarTransactions";
import {MonthInfo} from "./MonthInfo";
import {EnvelopePreview} from "./EnvelopePreview";
import {DrawerWrapper} from "6-shared/ui/DrawerWrapper";
import {TemplatedGroupPreview} from "./TemplatedGroupPreview";

export default function PnlTemplate2() {

    const [isTransactionsSidebarOpened, setIsTransactionsSidebarOpened] = useState(false)
    const [transactionsList, setTransactionsList] = useState<TTransaction[]>([])

    const [isEnvelopeOverviewOpen, setIsEnvelopeOverviewOpen] = useState(false)
    const [overviewSelectedEnvelope, setOverviewSelectedEnvelope] = useState<string | null>(null)

    const [isMonthOverviewOpen, setIsMonthOverviewOpen] = useState(false)
    const [overviewSelectedMonth, setOverviewSelectedMonth] = useState<string | null>(null)

    const [isGroupOverviewOpen, setIsGroupOverviewOpen] = useState(false)
    const [selectedGroup, setSelectedGroup] = useState<any | null>(null)

    const showPnlTransactionsCollection = (list: TTransaction[]) => {
        setTransactionsList(list)
        setIsTransactionsSidebarOpened(true)
    }

    const onOpenDetails = (e: any) => {
        setOverviewSelectedEnvelope(e)
        setIsEnvelopeOverviewOpen(true)
    }

    const onMonthNameClick = (name: string) => {
        setOverviewSelectedMonth(name)
        setIsMonthOverviewOpen(true)
    }

    const onGroupClick = (group: any) => {
        setSelectedGroup(group)
        setIsGroupOverviewOpen(true)
    }

    function closeSidebarAtAll() {
        setIsMonthOverviewOpen(false)
        setIsEnvelopeOverviewOpen(false)
        setIsTransactionsSidebarOpened(false)
        setIsGroupOverviewOpen(false)
    }


    const mainContent = (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                position: 'relative',
                height: 'fit-content',
                gap: 2,
                width: '100%',
            }}
        >

            <PnlReport
                onShowTransactions={showPnlTransactionsCollection}
                onOpenDetails={onOpenDetails}
                onMonthNameClick={onMonthNameClick}
                onGroupClick={onGroupClick}
            />

        </Box>
    )

    return (
        <>
            <Helmet>
                <title>PNL2 | Zerro</title>
                <meta name="description" content=""/>
                <link rel="canonical" href="https://zerro.app/budget"/>
            </Helmet>
            <DnDContext>
                <BudgetLayout mainContent={mainContent}/>
            </DnDContext>
            <PNLTransactionListDrawer
                open={isTransactionsSidebarOpened}
                onClose={closeSidebarAtAll}
                transactions={transactionsList}/>

            <DrawerWrapper position="right" isOpen={isMonthOverviewOpen}
                           onClose={closeSidebarAtAll}>
                {/*// @ts-ignore*/}
                <MonthInfo onClose={closeSidebarAtAll} month={overviewSelectedMonth}/>
            </DrawerWrapper>

            <DrawerWrapper position="right" isOpen={isEnvelopeOverviewOpen}
                           onClose={closeSidebarAtAll}>
                {/*// @ts-ignore*/}
                <EnvelopePreview id={overviewSelectedEnvelope}
                                 onClose={closeSidebarAtAll}/>
            </DrawerWrapper>

            <DrawerWrapper position="right" isOpen={isGroupOverviewOpen}
                           onClose={closeSidebarAtAll}>
                <TemplatedGroupPreview group={selectedGroup} onClose={closeSidebarAtAll}/>
            </DrawerWrapper>
        </>
    )
}


const BudgetLayout: FC<{
    mainContent: ReactElement
}> = props => {
    const {mainContent} = props

    return (
        <Box
            sx={{display: 'flex', height: '100dvh', width: '100vw', p: {xs: 0, md: 2}}}>
            <Paper
                sx={{
                    display: 'flex',
                    flexGrow: 1,
                    justifyContent: 'center',
                    height: '100%',
                    width: '100vw',
                    backgroundColor: 'var(--mui-palette-background-paper)',
                    overflow: 'auto',
                    px: {xs: 0, md: 0},
                    pb: 4,
                }}
            >
                {mainContent}
            </Paper>
        </Box>
    )
}

