import {Box, Grid, IconButton, InputAdornment, InputBase, Typography} from "@mui/material";
import React, {FC, useCallback} from "react";
import {useTranslation} from "react-i18next";
import {CloseIcon, NotesIcon} from "../../6-shared/ui/Icons";
import {cardStyle} from "./EnvelopePreview/shared";
import useTableDefaultTemplate, {TemplatedGroup} from "./table-template";
import {Tooltip} from '6-shared/ui/Tooltip'
import FunctionsIcon from '@mui/icons-material/Functions';

type TemplatedGroupPreviewProps = {
    group: TemplatedGroup
    onClose: () => void
}
export const TemplatedGroupPreview: FC<TemplatedGroupPreviewProps> = ({onClose, group}) => {
    return (
        <Box position="relative">
            <Header name={group.title} onClose={onClose}/>
            <Grid container spacing={2} px={3} pb={5} pt={3}>
                {group.formula &&
                    <Grid item xs={12}>
                        <Formula formula={group.formula}/>
                    </Grid>}
                <Grid item xs={12}>
                    <Comment group={group}/>
                </Grid>

            </Grid>
        </Box>
    )
}

const Header: FC<{
    name: string
    onClose: () => void
}> = ({name, onClose}) => {
    const {t} = useTranslation('common')
    return (
        <Box
            py={1}
            px={3}
            display="flex"
            alignItems="center"
            position="sticky"
            bgcolor="background.paper"
            zIndex={5}
            top={0}
        >
            <Box flexGrow={1} display="flex" minWidth={0} alignItems="center">
                <Typography variant="h6" component="span" noWrap>
                    {name}
                </Typography>
            </Box>

            <Tooltip title={t('close')}>
                <IconButton edge="end" onClick={onClose} children={<CloseIcon/>}/>
            </Tooltip>
        </Box>
    )
}

const Comment: FC<{
    group: TemplatedGroup
}> = ({group}) => {
    const {t} = useTranslation('common')
    let comment = ''
    switch (true) {
        case group?.editable_values:
            comment = 'Ручное поле'
            break
        case group?.type === 'formula':
            comment = 'Формyльное поле'
            break
        case group?.editable_source:
            comment = 'Статьи из PnL1'
            break
        default:
            comment = 'no comment yet'
    }

    return (
        <InputBase
            sx={cardStyle}
            placeholder={t('comment')}
            value={comment}
            disabled={true}
            multiline
            startAdornment={
                <InputAdornment position="start" component="label">
                    <NotesIcon/>
                </InputAdornment>
            }
        />
    )
}

const Formula: FC<{
    formula?: TemplatedGroup['formula']
}> = ({formula}) => {
    // @ts-ignore
    const [operand1Id, operator, operand2Id] = formula.split(' ');
    const {defaultPLTemplateStructure, findGroupById} = useTableDefaultTemplate()

    // @ts-ignore

    const operand1Group = findGroupById(operand1Id, defaultPLTemplateStructure);
    const operand2Group = findGroupById(operand2Id, defaultPLTemplateStructure);

    return (
        <InputBase
            sx={cardStyle}
            placeholder={'formula'}
            value={`${operand1Group?.title} ${operator} ${operand2Group?.title}`}
            disabled={true}
            multiline
            startAdornment={
                <InputAdornment position="start" component="label">
                    <FunctionsIcon/>
                </InputAdornment>
            }
        />
    )

}