import {createSelector, createSlice, PayloadAction} from '@reduxjs/toolkit';
import {RootState} from "./index";
import {getMonthList} from "../5-entities/envBalances/1 - monthList";
import {getTransactions} from "../5-entities/transaction/model";
import {TISOMonth, TTransaction} from "../6-shared/types";

export type IRocketDataItem = TTransaction & {
    transaction_id: string;
    pl_date?: TISOMonth;
    direction?: string;
    client_request?: string;
}

interface DataArrayState {
    items: IRocketDataItem[];
    ready: boolean
}

const initialState: DataArrayState = {
    items: [],
    ready: false
};

const dataArraySlice = createSlice({
    name: 'rocketData',
    initialState,
    reducers: {
        upsertItems: (state, action: PayloadAction<IRocketDataItem[]>) => {
            const newItems = action.payload;

            // Составляем хэш-карту для быстрого поиска по `transaction_id`
            const itemsMap = new Map(state.items.map(item => [item.transaction_id, item]));

            newItems.forEach(newItem => {
                const existingItem = itemsMap.get(newItem.transaction_id);

                if (existingItem) {
                    // Проверяем, есть ли различия между существующим и новым элементами
                    const isDifferent = Object.keys(newItem).some(
                        key => newItem[key as keyof IRocketDataItem] !== existingItem[key as keyof IRocketDataItem]
                    );

                    if (isDifferent) {
                        Object.assign(existingItem, newItem);
                    }
                } else {
                    // Если элемент новый, добавляем его в `items` и `itemsMap`
                    state.items.push(newItem);
                    itemsMap.set(newItem.transaction_id, newItem);
                }
            });
        },
        removeItem: (state, action: PayloadAction<string>) => {
            state.items = state.items.filter(item => item.transaction_id !== action.payload);
        },
        clearItems: (state) => {
            state.items = [];
        },
        setReady: (state, action: PayloadAction<boolean>) => {

            state.ready = action.payload
        }
    }
});

export const {upsertItems, removeItem, clearItems, setReady} = dataArraySlice.actions;

const isReady = (state: RootState) => state.rocketData.ready

export const isRocketDataReady = createSelector([isReady], ready => ready)

const rocketTransactions = (state: RootState) => state.rocketData.items;

const rocketTransactionsSelector = createSelector(
    [rocketTransactions],
    rocketTr => rocketTr
)

export const mergedTransactionsData = createSelector(
    [rocketTransactionsSelector, getTransactions],
    (rocketTr, zenTr) => {
        return Object.values(zenTr).map(transaction => {
            const additionalInfo = rocketTr.find(extra => extra.transaction_id === transaction.id)
            return additionalInfo ? {...transaction, ...additionalInfo} : transaction
        })
    })

const rocketTransactionsPnlMonthsList = (state: RootState) => state.rocketData.items.map(tr => (tr?.pl_date))

const rocketTransactionsPnlMonthsListSelector = createSelector(
    [rocketTransactionsPnlMonthsList],
    rocketTr => rocketTr
)

export const getMergedTransactionsMonths = createSelector(
    [rocketTransactionsPnlMonthsListSelector, getMonthList],
    (rocketTrMonths, zenTrMonths) => {
        // return [...rocketTrMonths, ...zenTrMonths]
        return [...new Set([...rocketTrMonths, ...zenTrMonths].sort())]
    })

export default dataArraySlice.reducer;
